import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FaGoogle, FaApple, FaUser, FaCreditCard, FaSignOutAlt, FaArrowLeft } from 'react-icons/fa';
import { Capacitor } from '@capacitor/core';
import { ClipLoader } from 'react-spinners';

const Settings = () => {
  console.log('Settings component rendered');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const isNativeMobile = Capacitor.isNativePlatform();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`,
          { token },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.data.valid) {
          setUser(response.data);
        } else {
          throw new Error('Invalid token');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        localStorage.removeItem('token');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('googleCredential');
    localStorage.removeItem('appleToken');
    navigate('/');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-pink-50">
        <ClipLoader color="#000000" loading={loading} size={50} />
      </div>
    );
  }

  return (
    <div className={`bg-pink-50 text-gray-800 min-h-screen ${isNativeMobile ? 'pt-8 pb-32' : ''}`}>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <header className="flex justify-between items-center mb-8">
          <Link to="/" className="flex items-center">
            <img src="/logo.png" alt="Palette Hunt Logo" className="h-10 w-auto mr-2" />
          </Link>
          <Link to="/" className="text-gray-600 hover:text-gray-800 transition-colors">
            <FaArrowLeft size={24} />
          </Link>
        </header>

        <div className="bg-white rounded-3xl shadow-xl p-8 mb-8">
          <h1 className="text-3xl font-bold mb-6 text-center text-gray-600">
            {user ? "Account Settings" : "Welcome to Palette Hunt"}
          </h1>
          
          {user ? (
            <div className="space-y-6">
              <div className="flex items-center p-4 bg-gray-100 rounded-2xl">
                <FaUser className="text-2xl text-gray-600 mr-4" />
                <div>
                  <h2 className="text-xl font-semibold">Account Information</h2>
                  <p className="text-gray-600">{user.email}</p>
                </div>
              </div>

              <div className="flex items-center p-4 bg-gray-100 rounded-2xl">
                <FaCreditCard className="text-2xl text-gray-600 mr-4" />
                <div>
                  <h2 className="text-xl font-semibold">Plan</h2>
                  <p className="text-gray-600">{user.plan || 'Free Plan'}</p>
                </div>
              </div>

              <div className="flex items-center p-4 bg-gray-100 rounded-2xl">
                {user.isAppleUser ? (
                  <FaApple className="text-2xl text-gray-600 mr-4" />
                ) : (
                  <FaGoogle className="text-2xl text-gray-600 mr-4" />
                )}
                <div>
                  <h2 className="text-xl font-semibold">Login Method</h2>
                  <p className="text-gray-600">
                    {user.isAppleUser ? 'Signed in with Apple' : 'Signed in with Google'}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center h-full space-y-6 text-center mt-12">
              <p className="text-xl">Log in to get color analyzed and access your personalized settings.</p>
              <Link
                to="/login"
                className="inline-block bg-gray-800 text-pink-50 font-semibold py-3 px-8 rounded-full hover:bg-opacity-90 transition duration-300"
              >
                Log In
              </Link>
            </div>
          )}
        </div>

        {user && (
          <div className="text-center">
            <button
              onClick={handleLogout}
              className="bg-red-500 text-white px-6 py-3 rounded-full hover:bg-red-600 transition-colors inline-flex items-center"
            >
              <FaSignOutAlt className="mr-2" />
              Log Out
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
