import React from 'react';
import { Link, useParams } from 'react-router-dom';

const translations = {
  en: {
    title: "Terms & Conditions",
    intro: "Welcome to Palette Hunt. By using our service, you agree to these terms. Please read them carefully.",
    sections: [
      {
        title: "1. Service Description",
        content: "Palette Hunt provides AI-powered color analysis services. We use advanced algorithms to analyze your photos and generate personalized color palettes."
      },
      {
        title: "2. User Responsibilities",
        content: "You are responsible for maintaining the confidentiality of your account and for all activities that occur under your account. You must not share your account credentials with others."
      },
      {
        title: "3. Age Requirement",
        content: "By using Palette Hunt, you confirm that you are at least 18 years old. If you are under 18, you may not use our services."
      },
      {
        title: "4. Generated Content Usage",
        content: "The AI-generated photos created through our service are yours to use. You have full rights to download, modify, distribute, or use these images in any way you see fit, without any restrictions from Palette Hunt."
      },
      {
        title: "5. Content Ownership",
        content: "You retain all rights to the photos you upload. By using our service, you grant us a limited access to use your photos for the purpose of color analysis. We delete all user photos after one week."
      },
      {
        title: "6. Prohibited Activities",
        content: "You agree not to misuse our services or help anyone else do so. For example, you must not attempt to access our services using a method other than the interface and instructions we provide."
      },
      {
        title: "7. Changes to Terms",
        content: "We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly."
      }
    ],
    agreement: "By using Palette Hunt, you agree to these terms. If you do not agree to these terms, please do not use our service.",
    returnHome: "Return to Home"
  },
  es: {
    title: "Términos y Condiciones",
    intro: "Bienvenido a Palette Hunt. Al utilizar nuestro servicio, aceptas estos términos. Por favor, léelos cuidadosamente.",
    sections: [
      {
        title: "1. Descripción del Servicio",
        content: "Palette Hunt proporciona servicios de análisis de color impulsados por IA. Utilizamos algoritmos avanzados para analizar tus fotos y generar paletas de colores personalizadas."
      },
      {
        title: "2. Responsabilidades del Usuario",
        content: "Eres responsable de mantener la confidencialidad de tu cuenta y de todas las actividades que ocurran bajo tu cuenta. No debes compartir las credenciales de tu cuenta con otros."
      },
      {
        title: "3. Requisito de Edad",
        content: "Al usar Palette Hunt, confirmas que tienes al menos 18 años. Si eres menor de 18 años, no puedes usar nuestros servicios."
      },
      {
        title: "4. Uso del Contenido Generado",
        content: "Las fotos generadas por IA creadas a través de nuestro servicio son tuyas para usar. Tienes plenos derechos para descargar, modificar, distribuir o usar estas imágenes de la manera que consideres conveniente, sin ninguna restricción por parte de Palette Hunt."
      },
      {
        title: "5. Propiedad del Contenido",
        content: "Conservas todos los derechos de las fotos que subes. Al usar nuestro servicio, nos otorgas un acceso limitado para usar tus fotos con el propósito de análisis de color. Eliminamos todas las fotos de los usuarios después de una semana."
      },
      {
        title: "6. Actividades Prohibidas",
        content: "Aceptas no hacer mal uso de nuestros servicios ni ayudar a nadie más a hacerlo. Por ejemplo, no debes intentar acceder a nuestros servicios utilizando un método distinto a la interfaz y las instrucciones que proporcionamos."
      },
      {
        title: "7. Cambios en los Términos",
        content: "Podemos modificar estos términos o cualquier término adicional que se aplique a un Servicio para, por ejemplo, reflejar cambios en la ley o cambios en nuestros Servicios. Debes revisar los términos regularmente."
      }
    ],
    agreement: "Al usar Palette Hunt, aceptas estos términos. Si no estás de acuerdo con estos términos, por favor no uses nuestro servicio.",
    returnHome: "Volver al Inicio"
  },
  pt: {
    title: "Termos e Condições",
    intro: "Bem-vindo à Palette Hunt. Ao usar nosso serviço, você concorda com estes termos. Por favor, leia-os cuidadosamente.",
    sections: [
      {
        title: "1. Descrição do Serviço",
        content: "A Palette Hunt fornece serviços de análise de cores alimentados por IA. Usamos algoritmos avançados para analisar suas fotos e gerar paletas de cores personalizadas."
      },
      {
        title: "2. Responsabilidades do Usuário",
        content: "Você é responsável por manter a confidencialidade de sua conta e por todas as atividades que ocorrem em sua conta. Você não deve compartilhar as credenciais de sua conta com outras pessoas."
      },
      {
        title: "3. Requisito de Idade",
        content: "Ao usar a Palette Hunt, você confirma que tem pelo menos 18 anos. Se você for menor de 18 anos, não poderá usar nossos serviços."
      },
      {
        title: "4. Uso de Conteúdo Gerado",
        content: "As fotos geradas por IA criadas através do nosso serviço são suas para usar. Você tem direitos totais para baixar, modificar, distribuir ou usar essas imagens da maneira que achar adequado, sem quaisquer restrições da Palette Hunt."
      },
      {
        title: "5. Propriedade do Conteúdo",
        content: "Você mantém todos os direitos sobre as fotos que envia. Ao usar nosso serviço, você nos concede acesso limitado para usar suas fotos com o propósito de análise de cores. Excluímos todas as fotos dos usuários após uma semana."
      },
      {
        title: "6. Atividades Proibidas",
        content: "Você concorda em não fazer uso indevido de nossos serviços ou ajudar qualquer outra pessoa a fazê-lo. Por exemplo, você não deve tentar acessar nossos serviços usando um método diferente da interface e das instruções que fornecemos."
      },
      {
        title: "7. Alterações nos Termos",
        content: "Podemos modificar estes termos ou quaisquer termos adicionais que se apliquem a um Serviço para, por exemplo, refletir mudanças na lei ou mudanças em nossos Serviços. Você deve consultar os termos regularmente."
      }
    ],
    agreement: "Ao usar a Palette Hunt, você concorda com estes termos. Se você não concordar com estes termos, por favor não use nosso serviço.",
    returnHome: "Voltar para a Página Inicial"
  }
};

const TermsAndConditions = () => {
  const { language = 'en' } = useParams();
  const t = translations[language] || translations.en;

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <div className="max-w-4xl mx-auto px-4 py-16">
        <div className="flex items-center mb-8">
          <img src="/logo.png" alt="Palette Hunt Logo" className="h-10 w-auto mr-3" />
          <span className="text-2xl text-white font-bold">Palette Hunt</span>
        </div>

        <h1 className="text-4xl font-bold mb-8">{t.title}</h1>
        
        <p className="mb-6">{t.intro}</p>
        
        {t.sections.map((section, index) => (
          <div key={index}>
            <h2 className="text-2xl font-semibold mt-8 mb-4">{section.title}</h2>
            <p className="mb-6">{section.content}</p>
          </div>
        ))}
        
        <p className="mt-12">{t.agreement}</p>
        
        <div className="mt-12">
          <Link to={language === 'en' ? '/' : `/${language}`} className="text-primary hover:text-secondary">{t.returnHome}</Link>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;