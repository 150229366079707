import React from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaArrowLeft } from 'react-icons/fa';

const Contact = () => {
  return (
    <div className="bg-pink-100 min-h-screen flex flex-col items-center justify-center p-4">
      <div className="text-center max-w-md bg-white rounded-lg shadow-lg p-8">
        <img 
          src="/logo.png" 
          alt="Palette Hunt Logo" 
          className="w-24 h-24 mx-auto mb-6"
        />
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Contact Us</h1>
        <p className="text-xl text-gray-700 mb-8">
          We'd love to hear from you! If you have any questions, suggestions, or just want to say hello, please reach out to us at:
        </p>
        <a 
          href="mailto:hi@palettehunt.com" 
          className="text-2xl font-semibold text-blue-600 hover:underline flex items-center justify-center"
        >
          <FaEnvelope className="mr-2" />
          hi@palettehunt.com
        </a>
        <p className="mt-8 text-gray-600">
          We typically respond within 24 hours.
        </p>
      </div>
      <Link 
        to="/" 
        className="mt-12 text-gray-600 hover:text-gray-800 transition-colors flex items-center"
      >
        <FaArrowLeft className="mr-2" />
        Back to Home
      </Link>
    </div>
  );
};

export default Contact;
