import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';
import { Capacitor } from '@capacitor/core';

const WaitingScreen = () => {
  const [isNativeMobile, setIsNativeMobile] = useState(false);

  useEffect(() => {
    setIsNativeMobile(Capacitor.isNativePlatform());
  }, []);

  return (
    <div className={`${isNativeMobile ? 'bg-pink-50 text-gray-800' : 'bg-gradient-to-br from-gray-900 to-gray-800 text-white'} min-h-screen flex flex-col ${isNativeMobile ? 'pt-8 pb-32' : ''}`}>
      <div className="container mx-auto px-4 pt-16 pb-16 flex flex-col items-center justify-center flex-grow">
        <div className="flex justify-center mb-12">
          <img src="/logo.png" alt="Palette Hunt Logo" className="h-20 w-auto" />
        </div>
        
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="mb-8"
        >
          <Check className={`w-24 h-24 ${isNativeMobile ? 'text-green-500' : 'text-green-500'} mx-auto`} />
        </motion.div>
        
        <motion.h1 
          className={`text-4xl sm:text-5xl font-bold mb-8 text-center ${isNativeMobile ? 'text-gray-800' : 'bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text'}`}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Your Color Journey Has Begun!
        </motion.h1>
        
        <motion.div 
          className="text-center max-w-2xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <p className={`text-xl mb-4 ${isNativeMobile ? 'text-gray-700' : 'text-white'}`}>
            We are creating your color analysis. You will receive an email once your results are finished.
          </p>
          <p className={`text-xl ${isNativeMobile ? 'text-gray-700' : 'text-gray-300'}`}>
            You can also check back in about an hour to see your results.
          </p>
        </motion.div>
      </div>
      
      {!isNativeMobile && (
        <footer className="py-6 text-center text-gray-500">
          <p>&copy; {new Date().getFullYear()} Palette Hunt. All rights reserved.</p>
        </footer>
      )}
    </div>
  );
};

export default WaitingScreen;
