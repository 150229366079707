import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { InAppReview } from '@capacitor-community/in-app-review';

const slides = [
  { image: "/slide0.jpeg" },
  { image: "/slide1.png" },
  { image: "/slide2.jpeg" },
  { image: "/slide3.jpeg" },
  { image: "/slide4.jpeg" }
];

const OnboardingSlides = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const navigate = useNavigate();
  const isNative = Capacitor.isNativePlatform();

  useEffect(() => {
    const updateDimensions = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const isSmallScreen = width <= 375; // iPhone SE width

      if (isSmallScreen) {
        const containerWidth = width * 0.87;
        const containerHeight = height * 0.85;
        const imageAspectRatio = 9 / 19.5; // Assuming this is the aspect ratio of your images
        
        let finalWidth, finalHeight;
        if (containerWidth / containerHeight > imageAspectRatio) {
          finalHeight = containerHeight;
          finalWidth = containerHeight * imageAspectRatio;
        } else {
          finalWidth = containerWidth;
          finalHeight = containerWidth / imageAspectRatio;
        }

        setDimensions({ width: finalWidth, height: finalHeight });
      } else {
        setDimensions({ width: width * 0.82, height: height * 0.82 });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const handleSwipe = (direction) => {
    if (direction === 'left' && currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else if (direction === 'right' && currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    } else if (direction === 'right' && currentSlide === 0) {
      handleGetStarted();
    } else if (direction === 'left' && currentSlide === slides.length - 1) {
      handleGetStarted();
    } else if (direction === 'up' && currentSlide === slides.length - 1) {
      handleGetStarted();
    }
  };

  const handleGetStarted = async () => {
    localStorage.setItem('hasSeenOnboarding', 'true');
    console.log('Get started finished');
    
    if (isNative) {
      try {
        const { isAvailable } = await InAppReview.isAvailable();
        if (isAvailable) {
          await InAppReview.requestReview();
        }
      } catch (error) {
        console.error('Error requesting in-app review:', error);
      }
      window.location.href = '/';
    } else {
      navigate('/');
    }
  };

  return (
    <div className="h-screen w-screen relative overflow-hidden bg-white flex flex-col items-center justify-center">
      <div 
        className="relative rounded-3xl overflow-hidden shadow-xl"
        style={{ 
          width: `${dimensions.width}px`, 
          height: `${dimensions.height}px`,
        }}
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={currentSlide}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
            className="w-full h-full"
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                handleSwipe('left');
              } else if (swipe > swipeConfidenceThreshold) {
                handleSwipe('right');
              }
            }}
          >
            <img 
              src={slides[currentSlide].image} 
              alt={`Slide ${currentSlide + 1}`} 
              className="w-full h-full object-contain"
            />
          </motion.div>
        </AnimatePresence>
      </div>
      
      <div className="absolute bottom-8 left-0 right-0 flex justify-center">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 rounded-full mx-1 ${
              index === currentSlide ? 'bg-gray-800' : 'bg-gray-400'
            }`}
          />
        ))}
      </div>
    </div>
  );
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export default OnboardingSlides;
