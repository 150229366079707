import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Footer from './Footer';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Check, X, AlertCircle } from 'lucide-react';
import { ClipLoader } from 'react-spinners';
import imageCompression from 'browser-image-compression';
import { Capacitor } from '@capacitor/core';

const PaymentSuccessNotification = ({ onClose }) => {
  return (
    <div className="bg-green-500 rounded-lg shadow-md p-4 max-w-2xl w-full mx-auto mt-4">
      <div className="flex items-start justify-between">
        <div className="flex items-center space-x-3">
          <div className="bg-white rounded-full p-2">
            <Check className="text-green-500 w-6 h-6" />
          </div>
          <div>
            <h2 className="text-xl font-semibold text-white">Payment Successful</h2>
            <p className="text-green-100 mt-1">
              Your payment has been successfully received.
              <br />
              You have unlocked premium service now.
            </p>
          </div>
        </div>
        <button onClick={onClose} className="text-white hover:text-green-200">
          <X className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

const ConfirmationPopup = ({ onConfirm, onCancel }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-8 rounded-xl shadow-2xl max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4 text-white">Confirm Upload</h2>
        <p className="text-gray-300 mb-6">Are you sure you want to upload these images and start the color analysis process?</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onCancel}
            className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-opacity-90 transition-colors"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const ImageUpload = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPaymentSuccess, setShowPaymentSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [isNativeMobile, setIsNativeMobile] = useState(false);
  const [compressedImages, setCompressedImages] = useState([]);

  const API_ENDPOINT = process.env.REACT_APP_API_GATEWAY_ENDPOINT;

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    setIsNativeMobile(Capacitor.isNativePlatform());
  }, []);

  useEffect(() => {
    const verifySession = async () => {
      const queryParams = new URLSearchParams(location.search);
      const sessionId = queryParams.get('session_id');

      if (sessionId) {
        try {
          const response = await axios.get(`${API_ENDPOINT}/verify-success`, {
            params: { session_id: sessionId }
          });
          if (response.data.success) {
            localStorage.setItem('token', response.data.token);
            setPaymentSuccess(true);
            window.history.replaceState({}, document.title, "/upload");
          }
        } catch (error) {
          console.error('Payment verification error:', error);
        }
      }

      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token found, redirecting to login');
        navigate('/login');
      } else {
        try {
          console.log('Verifying token');
          const response = await axios.post(`${API_ENDPOINT}/verify-token`, { token });
          if (!response.data.valid) {
            console.log('Token invalid, redirecting to login');
            navigate('/login');
          }
        } catch (error) {
          console.error('Token verification failed:', error.response ? error.response.data : error.message);
          navigate('/login');
        }
      }
      setIsLoading(false);
    };

    verifySession();
  }, [navigate, location, API_ENDPOINT]);

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.85,  // Reduced from 0.8 to 0.5
      useWebWorker: true,
      fileType: 'image/jpeg',
    };

    try {
      const compressedFile = await imageCompression(file, options);
      console.log(`Compressed size: ${compressedFile.size / 1024 / 1024} MB`);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
      return file;
    }
  };

  const handleImageSelection = async (event) => {
    const files = Array.from(event.target.files);
    const newImages = [];
    const newCompressedImages = [];

    for (let file of files) {
      if (selectedImages.length + newImages.length >= 10) {
        setError("You can only upload up to 10 images.");
        break;
      }
      newImages.push(file);
      const compressedFile = await compressImage(file);
      newCompressedImages.push(compressedFile);
    }

    setSelectedImages([...selectedImages, ...newImages]);
    setCompressedImages([...compressedImages, ...newCompressedImages]);
    setError(null);
  };

  const handleRemoveImage = (index) => {
    setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index));
    setError(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedImages.length < 6) {
      alert('Please select at least 6 images.');
      return;
    }
    setShowConfirmation(true);
  };

  const handleConfirmUpload = async () => {
    setIsUploading(true);
    setShowConfirmation(false);

    try {
      const formData = new FormData();
      for (let i = 0; i < compressedImages.length; i++) {
        const file = compressedImages[i];
        const fileName = `${uuidv4()}-${file.name}`;
        formData.append('images', file, fileName);
      }

      console.log('compressedImages.length', compressedImages.length);

      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_ENDPOINT}/start-training`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity
      });

      if (response.data.success) {
        navigate('/waiting');
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      console.error('Upload error:', error);
      alert('An error occurred during upload. Please try again by reducing the total image size.');
    } finally {
      setIsUploading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-pink-50">
        <img src="/logo.png" alt="Loading" className="w-24 h-24 animate-pulse" />
      </div>
    );
  }

  return (
    <div className={`${isNativeMobile ? 'bg-pink-50 text-gray-800' : 'bg-gradient-to-br from-gray-900 to-gray-800 text-white'} min-h-screen flex flex-col ${isNativeMobile ? 'pt-16 pb-32' : ''}`}>
      {!isNativeMobile && (
        <Link to="/" className="mb-8 mt-8 flex justify-center">
          <img src="/logo.png" alt="Palette Hunt Logo" className="h-16 w-auto" />
        </Link>
      )}
      
      {paymentSuccess && (
        <PaymentSuccessNotification onClose={() => setPaymentSuccess(false)} />
      )}
      <main className="flex-grow container mx-auto px-4 py-8">
        <h1 className={`text-3xl font-bold mb-8 text-center ${isNativeMobile ? 'text-gray-800' : 'bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text animate-pulse'}`}>
          Upload Your Photos
        </h1>

        <div className={`flex flex-col ${isMobile ? '' : 'md:flex-row'} gap-8 mb-8`}>
          {isMobile ? (
            <>
              <div className="w-full text-gray-700">
                <h2 className="text-2xl font-semibold mb-4 text-gray-800">Instructions:</h2>
                <ol className="list-decimal list-inside space-y-3">
                  {[
                    "Upload 8-10 photos of yourself with different angles and good lighting conditions.",
                    "Make sure you are the only person in each photo.",
                    "Selfies work great, so feel free to use a lot of them. Do not send photos where you are far away from the camera.",
                    "Make sure to post pictures with good lighting conditions.",
                    "Ensure your face is clearly visible.",
                    "Try to ensure the image quality is as good as possible."
                  ].map((instruction, index) => (
                    <li key={<strong>{index + 1}.</strong>} className="text-base transition-all duration-300 ease-in-out transform hover:translate-x-2 hover:text-primary">
                      {instruction}
                    </li>
                  ))}
                </ol>
                <p className="mt-6 text-lg italic text-gray-600 border-l-4 border-secondary pl-4 font-bold">
                  We'll analyze your photos and email you when it is ready! It takes about one hour.
                </p>
              </div>

              <div className="w-full">
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div className="flex justify-center items-center w-full">
                    <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100">
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <svg className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                        <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                        <p className="text-xs text-gray-500">PNG, JPG or JPEG (MAX. 10 MB)</p>
                      </div>
                      <input 
                        id="dropzone-file" 
                        type="file" 
                        className="hidden" 
                        onChange={handleImageSelection}
                        accept="image/*"
                        multiple
                      />
                    </label>
                  </div>
                  {error && <p className="text-red-500 text-center">{error}</p>}
                  {selectedImages.length > 0 && (
                    <div>
                      <h3 className="text-xl font-semibold mb-2 text-gray-800">Selected Images ({selectedImages.length}/10)</h3>
                      <div className="grid grid-cols-3 gap-4">
                        {selectedImages.map((image, index) => (
                          <div key={index} className="relative group">
                            <img src={URL.createObjectURL(image)} alt={`Selected ${index + 1}`} className="w-full h-24 object-cover rounded-lg" />
                            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
                              <button
                                onClick={() => handleRemoveImage(index)}
                                className="text-white bg-red-500 rounded-full p-1"
                              >
                                <X size={16} />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <button
                    type="submit"
                    className={`w-full bg-gray-800 text-pink-50 font-semibold py-3 px-6 rounded-full hover:bg-opacity-90 transition duration-300 ${
                      selectedImages.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={selectedImages.length === 0 || isUploading}
                  >
                    {isUploading ? (
                      <ClipLoader color="#ffe4e6" loading={isUploading} size={24} />
                    ) : (
                      'Upload and Start Analysis'
                    )}
                  </button>
                </form>
              </div>
            </>
          ) : (
            <>
              <div className="w-full md:w-1/2 text-gray-300 order-2 md:order-1">
                <h2 className="text-3xl font-semibold mb-6 text-secondary">Instructions:</h2>
                <ol className="list-decimal list-inside space-y-4">
                  {[
                    "Upload 8-10 photos of yourself.",
                    "Make sure you are the only person in each photo.",
                    "Selfies work great, so feel free to use a lot of them.",
                    "Ensure your face is clearly visible.",
                    "Include both indoor and outdoor photos if possible.",
                    "Try to ensure the image quality is as good as possible."
                  ].map((instruction, index) => (
                    <li key={index} className="text-lg transition-all duration-300 ease-in-out transform hover:translate-x-2 hover:text-primary">
                      {instruction}
                    </li>
                  ))}
                </ol>
                <p className="mt-8 text-xl italic text-gray-400 border-l-4 border-secondary pl-4">
                  We'll analyze your photos and email you your personalized color palette within an hour!
                </p>
              </div>
              <div className="w-full md:w-1/2 order-1 md:order-2">
                <form onSubmit={handleSubmit} className="space-y-8">
                  <div className="flex justify-center items-center w-full">
                    <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64 bg-gray-800 rounded-lg border-2 border-gray-600 border-dashed cursor-pointer hover:bg-gray-700">
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <svg className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                        <p className="text-xs text-gray-400">PNG, JPG or WEBP (MAX. 10 images, 10 MB in total)</p>
                      </div>
                      <input id="dropzone-file" type="file" className="hidden" onChange={handleImageSelection} multiple accept="image/*" disabled={isUploading || selectedImages.length >= 10} />
                    </label>
                  </div>
                  <div className="text-right text-sm text-gray-400">
                    You can see below your uploaded pictures. Maximum 10 pics allowed.
                  </div>
                  {error && (
                    <p className="text-red-500 text-sm mt-2">{error}</p>
                  )}
                  <button 
                    type="submit" 
                    className="w-full bg-gradient-to-r from-primary to-secondary text-white font-bold py-3 px-6 rounded-full hover:opacity-90 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
                    disabled={isUploading || selectedImages.length === 0}
                  >
                    {isUploading ? "Uploading..." : "Analyze My Colors"}
                  </button>
                </form>
              </div>
            </>
          )}
        </div>

        {!isMobile && selectedImages.length > 0 && (
          <div className="w-full">
            <h3 className="text-2xl font-semibold mb-4">Selected Images ({selectedImages.length}/10)</h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
              {selectedImages.map((image, index) => (
                <div key={index} className="relative group">
                  <img src={URL.createObjectURL(image)} alt={`Selected ${index + 1}`} className="w-full h-40 object-cover rounded-lg" />
                  <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg">
                    <span className="text-white text-sm">Image {index + 1}</span>
                  </div>
                  <button
                    onClick={() => handleRemoveImage(index)}
                    className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                  >
                    <X size={16} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </main>

      {showConfirmation && (
        <ConfirmationPopup
          onConfirm={handleConfirmUpload}
          onCancel={() => setShowConfirmation(false)}
        />
      )}

      {!isNativeMobile && <Footer />}
    </div>
  );
};

export default ImageUpload;
