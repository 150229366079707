import React from 'react';
import { Link, useParams } from 'react-router-dom';

const translations = {
  en: {
    contact: "Contact",
    blog: "Blog",
    privacyPolicy: "Privacy Policy",
    termsConditions: "Terms & Conditions",
    allRightsReserved: "All rights reserved.",
  },
  es: {
    contact: "Contacto",
    blog: "Blog",
    privacyPolicy: "Política de Privacidad",
    termsConditions: "Términos y Condiciones",
    allRightsReserved: "Todos los derechos reservados.",
  },
  pt: {
    contact: "Contato",
    blog: "Blog",
    privacyPolicy: "Política de Privacidade",
    termsConditions: "Termos e Condições",
    allRightsReserved: "Todos os direitos reservados.",
  }
};

const Footer = () => {
  const { language = 'en' } = useParams();
  const t = translations[language] || translations.en;

  const getLanguagePrefix = (lang) => {
    return lang === 'en' ? '' : `/${lang}`;
  };

  return (
    <footer className="bg-gray-800 text-white py-6 sm:py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0 text-center md:text-left">
            <img src="/logo.png" alt="Palette Hunt Logo" className="h-8 w-auto mx-auto md:mx-0" />
            <p className="mt-2 text-sm sm:text-base">© 2024 Palette Hunt. {t.allRightsReserved}</p>
          </div>
          <div className="flex flex-wrap justify-center md:justify-end space-x-4 sm:space-x-6">
            <a href="mailto:hi@palettehunt.com" className="hover:text-primary transition duration-300 text-sm sm:text-base">{t.contact}</a>
            <Link to={`${getLanguagePrefix(language)}/blog`} className="hover:text-primary transition duration-300 text-sm sm:text-base">{t.blog}</Link>
            <Link to={`${getLanguagePrefix(language)}/privacy-policy`} className="hover:text-primary transition duration-300 text-sm sm:text-base">{t.privacyPolicy}</Link>
            <Link to={`${getLanguagePrefix(language)}/terms-and-conditions`} className="hover:text-primary transition duration-300 text-sm sm:text-base">{t.termsConditions}</Link>
            <a href="https://www.tiktok.com/@palettehunt" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition duration-300 text-sm sm:text-base">TikTok</a>
            <a href="https://www.instagram.com/palettehunt_app" target="_blank" rel="noopener noreferrer" className="hover:text-primary transition duration-300 text-sm sm:text-base">Instagram</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;