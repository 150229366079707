import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Footer from './Footer';

const translations = {
  en: {
    blogTitle: "Palette Hunt Blog: Color Analysis Insights",
    readMore: "Read more →",
  },
  es: {
    blogTitle: "Blog de Palette Hunt: Perspectivas del Análisis de Color",
    readMore: "Leer más →",
  },
  pt: {
    blogTitle: "Blog da Palette Hunt: Insights de Análise de Cores",
    readMore: "Leia mais →",
  }
};

const BlogPost = ({ title, excerpt, slug, image, date, language }) => {
  const t = translations[language] || translations.en;
  const altText = `${title} - Palette Hunt Color Analysis Blog`;
  return (
    <motion.div 
      className="bg-gray-800 rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:shadow-2xl flex flex-col h-full"
      whileHover={{ scale: 1.05 }}
    >
      <div className="h-56 overflow-hidden flex items-center justify-center">
        <LazyLoadImage
          src={image}
          alt={altText}
          effect="blur"
          className="w-full h-full object-cover object-center"
        />
      </div>
      <div className="p-6 flex-grow flex flex-col justify-between">
        <div>
          <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
          <p className="text-gray-400 mb-2">{date}</p>
          <p className="text-gray-400 mb-4">{excerpt}</p>
        </div>
        <Link 
          to={language === 'en' ? `/blog/${slug}` : `/${language}/blog/${slug}`}
          className="text-primary hover:text-secondary transition-colors duration-300 mt-auto"
          title={`Read more about ${title}`}
        >
          {t.readMore}
        </Link>
      </div>
    </motion.div>
  );
};

const Blog = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { language = 'en' } = useParams();
  const t = translations[language] || translations.en;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const blogPosts = [
    {
      title: {
        en: "Discovering Your Perfect Color Palette with Palette Hunt",
        es: "Descubre tu Paleta de Colores Perfecta con Palette Hunt",
        pt: "Descubra sua Paleta de Cores Perfeita com Palette Hunt"
      },
      slug: "discovering-your-perfect-color-palette",
      excerpt: {
        en: "Learn how Palette Hunt helps you identify the colors that complement your natural features and enhance your overall appearance.",
        es: "Aprende cómo Palette Hunt te ayuda a identificar los colores que complementan tus características naturales y mejoran tu apariencia general.",
        pt: "Aprenda como o Palette Hunt ajuda você a identificar as cores que complementam suas características naturais e melhoram sua aparência geral."
      },
      image: "/blog-image-1.png",
      date: "September 1, 2024",
      style: { transform: 'translateY(-10px)' }
    },
    {
      title: {
        en: "The Psychology of Color in Fashion: Insights from Palette Hunt",
        es: "La Psicología del Color en la Moda: Perspectivas de Palette Hunt",
        pt: "A Psicologia da Cor na Moda: Insights da Palette Hunt"
      },
      slug: "psychology-of-color-in-fashion",
      excerpt: {
        en: "Explore how different colors can affect mood, perception, and personal style choices with Palette Hunt's expert analysis.",
        es: "Explora cómo diferentes colores pueden afectar el estado de ánimo, la percepción y las elecciones de estilo personal con el análisis experto de Palette Hunt.",
        pt: "Explore como diferentes cores podem afetar o humor, a percepção e as escolhas de estilo pessoal com a análise especializada da Palette Hunt."
      },
      image: "/blog-image-2.png",
      date: "September 10, 2024",
      style: { transform: 'translateY(-10px)' }
    },
    {
      title: {
        en: "Beyond ChatGPT: How Palette Hunt Revolutionizes AI Color Analysis",
        es: "Más Allá de ChatGPT: Cómo Palette Hunt Revoluciona el Análisis de Color con IA",
        pt: "Além do ChatGPT: Como Palette Hunt Revoluciona a Análise de Cor com IA"
      },
      slug: "beyond-chatgpt-Palette Hunt-revolutionizes-ai-color-analysis",
      excerpt: {
        en: "Discover how Palette Hunt's visual AI takes color analysis beyond ChatGPT's text-based limitations, offering personalized and interactive style recommendations.",
        es: "Descubre cómo la IA visual de Palette Hunt lleva el análisis de color más allá de las limitaciones basadas en texto de ChatGPT, ofreciendo recomendaciones de estilo personalizadas e interactivas.",
        pt: "Descubra como a IA visual da Palette Hunt leva a análise de cor além das limitações baseadas em texto do ChatGPT, oferecendo recomendações de estilo personalizadas e interativas."
      },
      image: "/how_it_works_desktop.png",
      date: "September 25, 2024"
    },
    {
      title: {
        en: "True Autumn's Guide: Dressing Your Warm and Earthy Palette with Palette Hunt",
        es: "Guía del Otoño Verdadero: Vistiendo tu Paleta Cálida y Terrosa con Palette Hunt",
        pt: "Guia do Outono Verdadeiro: Vestindo sua Paleta Quente e Terrosa com Palette Hunt"
      },
      slug: "true-autumn-guide-dressing-warm-color-palette",
      excerpt: {
        en: "Discover how to embrace your True Autumn color palette and create stunning outfits that highlight your natural warmth and beauty using Palette Hunt.",
        es: "Descubre cómo abrazar tu paleta de colores de Otoño Verdadero y crear atuendos impresionantes que resalten tu calidez y belleza natural usando Palette Hunt.",
        pt: "Descubra como abraçar sua paleta de cores do Outono Verdadeiro e criar looks deslumbrantes que destacam seu calor e beleza natural usando Palette Hunt."
      },
      image: "/true-autumn-palette.jpg",
      date: "October 2, 2024"
    },
    {
      title: {
        en: "Color-analyze Yourself Like a PRO: Unlock Your Personal Palette with Palette Hunt",
        es: "Analiza tu Color Como un PRO: Descubre tu Paleta Personal con Palette Hunt",
        pt: "Analise Suas Cores Como um PRO: Desbloqueie Sua Paleta Pessoal com Palette Hunt"
      },
      slug: "color-analyze-yourself-like-a-pro",
      excerpt: {
        en: "Discover the secrets to professional color analysis at home with Palette Hunt. Learn how to identify your best colors and transform your style with expert tips and tricks.",
        es: "Descubre los secretos del análisis de color profesional en casa con Palette Hunt. Aprende a identificar tus mejores colores y transforma tu estilo con consejos y trucos de expertos.",
        pt: "Descubra os segredos da análise de cor profissional em casa com Palette Hunt. Aprenda a identificar suas melhores cores e transforme seu estilo com dicas e truques de especialistas."
      },
      image: "/traditional-analysis-1.webp",
      date: "October 3, 2024"
    },
    {
      title: {
        en: "Embracing the Deep Winter Color Palette: A Guide to Dramatic Elegance",
        es: "Abrazando la Paleta de Color Invierno Profundo: Una Guía para la Elegancia Dramática",
        pt: "Abraçando a Paleta de Cores do Inverno Profundo: Um Guia para Elegância Dramática"
      },
      slug: "deep-winter-color-palette",
      excerpt: {
        en: "Discover the power and elegance of the Deep Winter color palette. Learn how to harness your dramatic coloring and create stunning looks that showcase your natural beauty.",
        es: "Descubre el poder y la elegancia de la paleta de colores Invierno Profundo. Aprende a aprovechar tu coloración dramática y crear looks impresionantes que resalten tu belleza natural.",
        pt: "Descubra o poder e a elegância da paleta de cores do Inverno Profundo. Aprenda a aproveitar sua coloração dramática e criar looks deslumbrantes que mostrem sua beleza natural."
      },
      image: "/deep-winter-palette.jpg",
      date: "October 7, 2024"
    },
    {
      title: {
        en: "Embracing the Soft Autumn Color Palette: Your Guide to Effortless Elegance with Palette Hunt",
        es: "Abrazando la Paleta de Color Otoño Suave: Tu Guía para una Elegancia Sin Esfuerzo con Palette Hunt",
        pt: "Abraçando a Paleta de Cores do Outono Suave: Seu Guia para Elegância Sem Esforço com Palette Hunt"
      },
      slug: "soft-autumn-color-palette-guide",
      excerpt: {
        en: "Discover the magic of the soft autumn color palette and learn how to incorporate these muted, warm hues into your wardrobe for a timeless and sophisticated look with Palette Hunt.",
        es: "Descubre la magia de la paleta de colores del otoño suave y aprende a incorporar estos tonos cálidos y apagados en tu guardarropa para un look atemporal y sofisticado con Palette Hunt.",
        pt: "Descubra a magia da paleta de cores do outono suave e aprenda a incorporar esses tons quentes e suaves em seu guarda-roupa para um visual atemporal e sofisticado com Palette Hunt."
      },
      image: "/soft-autumn-palette.jpg",
      date: "October 4, 2024"
    },
    {
      title: {
        en: "Color Analysis Test: Discover Your Perfect Palette with Palette Hunt",
        es: "Prueba de Análisis de Color: Descubre tu Paleta Perfecta con Palette Hunt",
        pt: "Teste de Análise de Cor: Descubra Sua Paleta Perfeita com Palette Hunt"
      },
      slug: "color-analysis-test",
      excerpt: {
        en: "Unlock the secrets of your personal color palette with Palette Hunt's comprehensive color analysis test. Learn how to enhance your natural beauty and style.",
        es: "Descubre los secretos de tu paleta de colores personal con la prueba de análisis de color completa de Palette Hunt. Aprende cómo realzar tu belleza y estilo natural.",
        pt: "Desbloqueie os segredos da sua paleta de cores pessoal com o teste abrangente de análise de cores da Palette Hunt. Aprenda como realçar sua beleza e estilo naturais."
      },
      image: "/color-analysis-test.jpg",
      date: "October 5, 2024"
    },
    {
      title: {
        en: "Photo Color Analysis: Discover Your Perfect Palette with Palette Hunt",
        es: "Colorimetría con foto: Descubre tu paleta perfecta con Palette Hunt",
        pt: "Análise de Cores por Foto: Descubra sua Paleta Perfeita com Palette Hunt"
      },
      slug: "colometria-con-foto",
      excerpt: {
        en: "Learn how Palette Hunt's AI-powered photo color analysis can help you find your ideal colors and transform your style.",
        es: "Descubre cómo el análisis de color por foto impulsado por IA de Palette Hunt puede ayudarte a encontrar tus colores ideales y transformar tu estilo.",
        pt: "Descubra como a análise de cores por foto alimentada por IA da Palette Hunt pode ajudar você a encontrar suas cores ideais e transformar seu estilo."
      },
      image: "/blog-image-3.png",
      date: "October 7, 2024"
    },
    {
      title: {
        en: "Colors That Flatter Pale Skin: A Comprehensive Guide",
        es: "Colores que Favorecen la Piel Pálida: Una Guía Completa",
        pt: "Cores que Valorizam a Pele Clara: Um Guia Abrangente"
      },
      slug: "colours-good-with-pale-skin",
      excerpt: {
        en: "Discover the most flattering colors for pale skin tones and learn how to create stunning outfits that enhance your natural beauty with Palette Hunt's expert advice.",
        es: "Descubre los colores más favorecedores para tonos de piel pálida y aprende a crear atuendos impresionantes que realcen tu belleza natural con los consejos expertos de Palette Hunt.",
        pt: "Descubra as cores mais favoráveis para tons de pele clara e aprenda a criar looks deslumbrantes que realçam sua beleza natural com os conselhos especializados da Palette Hunt."
      },
      image: "/pale-skin-colors.jpeg",
      date: "October 10, 2024"
    },
    {
      title: {
        en: "Color Analysis Quiz: Discover Your Perfect Palette with Palette Hunt",
        es: "Cuestionario de Análisis de Color: Descubre tu Paleta Perfecta con Palette Hunt",
        pt: "Questionário de Análise de Cor: Descubra sua Paleta Perfeita com Palette Hunt"
      },
      slug: "color-analysis-quiz",
      excerpt: {
        en: "Discover why color analysis is crucial for your personal style. Learn how the right colors can boost your confidence, simplify shopping, enhance your natural beauty, and create a cohesive wardrobe that truly reflects you.",
        es: "Descubre por qué el análisis de color es crucial para tu estilo personal. Aprende cómo los colores adecuados pueden aumentar tu confianza, simplificar tus compras, realzar tu belleza natural y crear un guardarropa coherente que realmente te refleje.",
        pt: "Descubra por que a análise de cores é crucial para o seu estilo pessoal. Aprenda como as cores certas podem aumentar sua confiança, simplificar suas compras, realçar sua beleza natural e criar um guarda-roupa coeso que realmente reflete você."
      },
      image: "/color-analysis-quiz.webp",
      date: "October 11, 2024"
    },
  ];

  // Sort blog posts by date (most recent first)
  const sortedBlogPosts = blogPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <div className="bg-gray-900 min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <Link to={language === 'en' ? '/' : `/${language}`} className="inline-flex items-center mb-8">
          <img src="/logo.png" alt="Palette Hunt Logo" className="h-12 w-auto sm:h-16" />
          <span className="ml-2 text-2xl font-bold text-white">Palette Hunt</span>
        </Link>
        
        <h1 className="text-4xl sm:text-5xl font-bold mb-12 text-center bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text">
          {t.blogTitle}
        </h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-items-center">
          {sortedBlogPosts.map((post, index) => (
            <BlogPost 
              key={index} 
              title={post.title[language] || post.title.en}
              excerpt={post.excerpt[language] || post.excerpt.en}
              slug={post.slug}
              image={post.image}
              date={post.date}
              language={language}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;