import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaHome, FaPalette, FaPaintBrush } from 'react-icons/fa';

const BottomTabNavigator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('/');

  useEffect(() => {
    // Set the active tab based on the current path
    setActiveTab(location.pathname);
  }, [location.pathname]);

  const checkPaymentAndNavigate = (path) => {
    setActiveTab(path);
    navigate(path);
  };

  const tabs = [
    { name: 'Menu', path: '/', icon: FaHome },
    { name: 'Color Analysis', path: '/color-analysis', icon: FaPalette },
    { name: 'Playground', path: '/playground', icon: FaPaintBrush },
  ];

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 flex justify-around items-start h-20 pb-safe pt-2">
      {tabs.map((tab) => (
        <div
          key={tab.name}
          className={`flex flex-col items-center justify-start w-full h-full ${
            activeTab === tab.path ? 'text-pink-500' : 'text-pink-50'
          }`}
          onClick={() => checkPaymentAndNavigate(tab.path)}
        >
          <tab.icon size={24} className={`mb-1 ${activeTab === tab.path ? 'text-pink-500' : 'text-pink-50'}`} />
          <span className={`text-xs ${activeTab === tab.path ? 'text-pink-500' : 'text-pink-50'}`}>{tab.name}</span>
        </div>
      ))}
    </div>
  );
};

export default BottomTabNavigator;