import React from 'react';
import { Link, useParams } from 'react-router-dom';

const translations = {
  en: {
    title: "Privacy Policy",
    intro: "At Palette Hunt, we prioritize your privacy and the protection of your data. All information collected, both personal and non-personal, is safeguarded with the highest standards.",
    commitment: "Palette Hunt is committed to maintaining the trust and confidence of our users. We want you to understand when and why we collect your personal information, how we use it, the conditions under which we may disclose it to others, and how we keep it secure.",
    keyPoints: "Key Points:",
    keyPointsList: [
      "We delete all user photos after one week",
      "We only keep your email address for identification purposes",
      "We do not share your personal information with third parties",
      "We use the photos you provide to train our AI model, but this data is only accessible to our internal team"
    ],
    infoCollection: "Information Collection and Use",
    infoCollectionText: "We collect your email address for account identification and communication purposes. The photos you upload are used solely for color analysis and are automatically deleted after one week.",
    dataSecurity: "Data Security",
    dataSecurityText: "We implement robust security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.",
    yourRights: "Your Rights",
    yourRightsText: "You have the right to access, correct, or delete your personal information at any time. Please contact us if you wish to exercise these rights.",
    contact: "For any questions or concerns regarding our privacy practices, please contact us at hi@palettehunt.com",
    returnHome: "Return to Home",
    faceDataTitle: "How We Use Your Information",
    faceDataText: [
      "Face Data Collection and Processing:",
      "• We collect facial images that you voluntarily upload for the purpose of color analysis and AI model training.",
      "• Your face data is used strictly to train our AI model to provide personalized color recommendations.",
      "• We do not use face data for any other purpose than what is explicitly stated in this policy.",
      
      "Data Storage and Retention:",
      "• All facial images are automatically deleted after one week.",
      "• We maintain strict security protocols to protect your face data during this period.",
      "• Our AI training process anonymizes your data, ensuring no personal identification is possible after processing.",
      
      "Data Sharing and Service Providers:",
      "• We use Replicate.com as our AI service provider strictly for model training purposes.",
      "• Our service provider does not store any face data - it is only used as input at the moment of training through their API.",
      "• Since the service is used through API calls, neither we nor the provider retain access to the submitted face data after processing.",
      "• Your face data is never sold, rented, or shared with third parties for marketing or other purposes.",
      
      "Your Control Over Face Data:",
      "• You can request immediate deletion of your face data at any time.",
      "• You have the right to withdraw consent for face data processing.",
      "• Contact us at hi@palettehunt.com to exercise these rights."
    ]
  },
  es: {
    title: "Política de Privacidad",
    intro: "En Palette Hunt, priorizamos su privacidad y la protección de sus datos. Toda la información recopilada, tanto personal como no personal, está protegida con los más altos estándares.",
    commitment: "Palette Hunt se compromete a mantener la confianza de nuestros usuarios. Queremos que entienda cuándo y por qué recopilamos su información personal, cómo la usamos, las condiciones bajo las cuales podemos divulgarla a otros y cómo la mantenemos segura.",
    keyPoints: "Puntos Clave:",
    keyPointsList: [
      "Eliminamos todas las fotos de los usuarios después de una semana",
      "Solo guardamos su dirección de correo electrónico para fines de identificación",
      "No compartimos su información personal con terceros",
      "Usamos las fotos que proporciona para entrenar nuestro modelo de IA, pero estos datos solo son accesibles para nuestro equipo interno"
    ],
    infoCollection: "Recopilación y Uso de Información",
    infoCollectionText: "Recopilamos su dirección de correo electrónico para fines de identificación de cuenta y comunicación. Las fotos que sube se utilizan únicamente para el análisis de color y se eliminan automáticamente después de una semana.",
    dataSecurity: "Seguridad de Datos",
    dataSecurityText: "Implementamos medidas de seguridad robustas para proteger su información personal contra acceso, alteración, divulgación o destrucción no autorizados.",
    yourRights: "Sus Derechos",
    yourRightsText: "Tiene derecho a acceder, corregir o eliminar su información personal en cualquier momento. Póngase en contacto con nosotros si desea ejercer estos derechos.",
    contact: "Para cualquier pregunta o inquietud sobre nuestras prácticas de privacidad, contáctenos en hi@palettehunt.com",
    returnHome: "Volver al Inicio",
    faceDataTitle: "Cómo Usamos Su Información",
    faceDataText: [
      "Recopilación y Procesamiento de Datos Faciales:",
      "• Recopilamos imágenes faciales que usted carga voluntariamente para el análisis de color y entrenamiento del modelo de IA.",
      "• Sus datos faciales se utilizan estrictamente para entrenar nuestro modelo de IA para proporcionar recomendaciones de color personalizadas.",
      "• No utilizamos datos faciales para ningún otro propósito que no esté explícitamente establecido en esta política.",
      
      "Almacenamiento y Retención de Datos:",
      "• Todas las imágenes faciales se eliminan automáticamente después de una semana.",
      "• Mantenemos protocolos de seguridad estrictos para proteger sus datos faciales durante este período.",
      "• Nuestro proceso de entrenamiento de IA anonimiza sus datos, asegurando que no sea posible la identificación personal después del procesamiento.",
      
      "Compartición de Datos y Proveedores de Servicios:",
      "• Compartimos datos faciales solo con nuestro proveedor de servicios de IA estrictamente para fines de entrenamiento del modelo.",
      "• Nuestro proveedor de servicios está contractualmente obligado a mantener la confidencialidad y seguridad de los datos.",
      "• Sus datos faciales nunca se venden, alquilan o comparten con terceros para marketing u otros fines.",
      
      "Su Control Sobre los Datos Faciales:",
      "• Puede solicitar la eliminación inmediata de sus datos faciales en cualquier momento.",
      "• Tiene derecho a retirar el consentimiento para el procesamiento de datos faciales.",
      "• Contáctenos en hi@palettehunt.com para ejercer estos derechos."
    ]
  },
  pt: {
    title: "Política de Privacidade",
    intro: "Na Palette Hunt, priorizamos sua privacidade e a proteção de seus dados. Todas as informações coletadas, pessoais e não pessoais, são protegidas com os mais altos padrões.",
    commitment: "A Palette Hunt está comprometida em manter a confiança de nossos usuários. Queremos que você entenda quando e por que coletamos suas informações pessoais, como as usamos, as condições sob as quais podemos divulgá-las a terceiros e como as mantemos seguras.",
    keyPoints: "Pontos Principais:",
    keyPointsList: [
      "Excluímos todas as fotos dos usuários após uma semana",
      "Mantemos apenas seu endereço de e-mail para fins de identificação",
      "Não compartilhamos suas informações pessoais com terceiros",
      "Usamos as fotos que você fornece para treinar nosso modelo de IA, mas esses dados são acessíveis apenas à nossa equipe interna"
    ],
    infoCollection: "Coleta e Uso de Informações",
    infoCollectionText: "Coletamos seu endereço de e-mail para fins de identificação de conta e comunicação. As fotos que você envia são usadas exclusivamente para análise de cores e são automaticamente excluídas após uma semana.",
    dataSecurity: "Segurança de Dados",
    dataSecurityText: "Implementamos medidas de segurança robustas para proteger suas informações pessoais contra acesso, alteração, divulgação ou destruição não autorizados.",
    yourRights: "Seus Direitos",
    yourRightsText: "Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Entre em contato conosco se desejar exercer esses direitos.",
    contact: "Para quaisquer perguntas ou preocupações sobre nossas práticas de privacidade, entre em contato conosco em hi@palettehunt.com",
    returnHome: "Voltar para a Página Inicial",
    faceDataTitle: "Como Usamos Suas Informações",
    faceDataText: [
      "Coleta e Processamento de Dados Faciais:",
      "• Coletamos imagens faciais que você carrega voluntariamente para análise de cores e treinamento do modelo de IA.",
      "• Seus dados faciais são usados estritamente para treinar nosso modelo de IA para fornecer recomendações de cores personalizadas.",
      "• Não usamos dados faciais para nenhuma outra finalidade além do que está explicitamente declarado nesta política.",
      
      "Armazenamento e Retenção de Dados:",
      "• Todas as imagens faciais são automaticamente excluídas após uma semana.",
      "• Mantemos protocolos de segurança rigorosos para proteger seus dados faciais durante este período.",
      "• Nosso processo de treinamento de IA anonimiza seus dados, garantindo que nenhuma identificação pessoal seja possível após o processamento.",
      
      "Compartilhamento de Dados e Provedores de Serviços:",
      "• Compartilhamos dados faciais apenas com nosso provedor de serviços de IA estritamente para fins de treinamento do modelo.",
      "• Nosso provedor de serviços é contratualmente obrigado a manter a confidencialidade e segurança dos dados.",
      "• Seus dados faciais nunca são vendidos, alugados ou compartilhados com terceiros para marketing ou outros fins.",
      
      "Seu Controle Sobre os Dados Faciais:",
      "• Você pode solicitar a exclusão imediata de seus dados faciais a qualquer momento.",
      "• Você tem o direito de retirar o consentimento para o processamento de dados faciais.",
      "• Entre em contato conosco em hi@palettehunt.com para exercer esses direitos."
    ]
  }
};

const PrivacyPolicy = () => {
  const { language = 'en' } = useParams();
  const t = translations[language] || translations.en;

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <div className="max-w-4xl mx-auto px-4 py-16">
        <div className="flex items-center mb-8">
          <img src="/logo.png" alt="Palette Hunt Logo" className="h-10 w-auto mr-3" />
          <span className="text-2xl text-white font-bold">Palette Hunt</span>
        </div>

        <h1 className="text-4xl font-bold mb-8">{t.title}</h1>
        
        <p className="mb-6">{t.intro}</p>
        
        <p className="mb-6">{t.commitment}</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">{t.keyPoints}</h2>
        <ul className="list-disc list-inside mb-6">
          {t.keyPointsList.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">{t.infoCollection}</h2>
        <p className="mb-6">{t.infoCollectionText}</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">{t.dataSecurity}</h2>
        <p className="mb-6">{t.dataSecurityText}</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">{t.yourRights}</h2>
        <p className="mb-6">{t.yourRightsText}</p>
        
        <p className="mt-12">{t.contact}</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">{t.faceDataTitle}</h2>
        {t.faceDataText.map((text, index) => (
          <p key={index} className={`mb-${text.startsWith('•') ? '2' : '6'} ${text.startsWith('•') ? 'ml-4' : 'font-semibold'}`}>
            {text}
          </p>
        ))}

        <div className="mt-12">
          <Link to={language === 'en' ? '/' : `/${language}`} className="text-primary hover:text-secondary">{t.returnHome}</Link>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;