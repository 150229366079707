const locations = [
  // Countries
  { 
    name: "United States", 
    slug: "united-states", 
    type: "country",
    pricingRange: "$100 - $500",
    businesses: [
      "Color Me Beautiful",
      "House of Colour",
      "ColorStyle Studio"
    ],
    description: "The United States offers a wide range of color analysis services, from high-end fashion consultants to more affordable options. The diverse culture and fashion-forward cities make it a popular choice for color analysis."
  },
  { 
    name: "Canada", 
    slug: "canada", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [
      "Color Me Beautiful",
      "House of Colour"
    ],
    description: "Canada's color analysis services reflect its diverse culture and climate. Analysts often specialize in palettes suitable for both urban and natural settings."
  },
  { 
    name: "United Kingdom", 
    slug: "united-kingdom", 
    type: "country",
    pricingRange: "$150 - $450",
    businesses: [
      "Color Me Beautiful",
      "House of Colour"
    ],
    description: "The United Kingdom offers a variety of color analysis services, from traditional methods to modern techniques. The country's rich history and fashion scene make it a unique destination for color analysis."
  },
  { 
    name: "France", 
    slug: "france", 
    type: "country",
    pricingRange: "$200 - $500",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "France is known for its high-end fashion and sophisticated color analysis services. Analysts often focus on chic and elegant palettes that reflect the country's style."
  },
  { 
    name: "Germany", 
    slug: "germany", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [
      "House of Colour"
    ],
    description: "Germany's color analysis services are known for their precision and attention to detail. Analysts often specialize in practical and versatile palettes suitable for various occasions."
  },
  { 
    name: "Italy", 
    slug: "italy", 
    type: "country",
    pricingRange: "$150 - $450",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Italy offers a range of color analysis services that reflect its rich cultural heritage and fashion-forward cities. Analysts often focus on vibrant and stylish palettes."
  },
  { 
    name: "Spain", 
    slug: "spain", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [
      "House of Colour"
    ],
    description: "Spain's color analysis services are influenced by its sunny climate and vibrant culture. Analysts often specialize in bright and bold palettes suitable for the lively fashion scene."
  },
  { 
    name: "Netherlands", 
    slug: "netherlands", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "The Netherlands offers color analysis services that reflect its modern and innovative approach to fashion. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Sweden", 
    slug: "sweden", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Sweden's color analysis services are known for their minimalist and functional approach. Analysts often specialize in neutral and understated palettes."
  },
  { 
    name: "Norway", 
    slug: "norway", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Norway offers color analysis services that reflect its natural beauty and serene landscapes. Analysts often focus on palettes inspired by nature."
  },
  { 
    name: "Denmark", 
    slug: "denmark", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Denmark's color analysis services are known for their simplicity and elegance. Analysts often specialize in clean and modern palettes."
  },
  { 
    name: "Finland", 
    slug: "finland", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Finland offers color analysis services that reflect its unique blend of tradition and modernity. Analysts often focus on palettes that are both practical and stylish."
  },
  { 
    name: "Switzerland", 
    slug: "switzerland", 
    type: "country",
    pricingRange: "$150 - $450",
    businesses: [],
    description: "Switzerland's color analysis services are known for their precision and attention to detail. Analysts often specialize in elegant and sophisticated palettes."
  },
  { 
    name: "Austria", 
    slug: "austria", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Austria offers color analysis services that reflect its rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Belgium", 
    slug: "belgium", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Belgium's color analysis services are known for their creativity and innovation. Analysts often specialize in unique and artistic palettes."
  },
  { 
    name: "Portugal", 
    slug: "portugal", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Portugal offers color analysis services that reflect its vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  { 
    name: "Greece", 
    slug: "greece", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Greece's color analysis services are influenced by its rich history and beautiful landscapes. Analysts often specialize in palettes inspired by the Mediterranean."
  },
  { 
    name: "Poland", 
    slug: "poland", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Poland offers color analysis services that reflect its diverse culture and modern fashion scene. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Czech Republic", 
    slug: "czech-republic", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "The Czech Republic's color analysis services are known for their creativity and attention to detail. Analysts often specialize in unique and artistic palettes."
  },
  { 
    name: "Hungary", 
    slug: "hungary", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Hungary offers color analysis services that reflect its rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Russia", 
    slug: "russia", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Russia's color analysis services are known for their bold and dramatic approach. Analysts often specialize in vibrant and striking palettes."
  },
  { 
    name: "Mexico", 
    slug: "mexico", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Mexico offers color analysis services that reflect its vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  { 
    name: "Brazil", 
    slug: "brazil", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Brazil's color analysis services are influenced by its lively culture and beautiful landscapes. Analysts often specialize in bold and vibrant palettes."
  },
  { 
    name: "Argentina", 
    slug: "argentina", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Argentina offers color analysis services that reflect its rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Colombia", 
    slug: "colombia", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Colombia's color analysis services are known for their creativity and innovation. Analysts often specialize in unique and artistic palettes."
  },
  { 
    name: "Peru", 
    slug: "peru", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Peru offers color analysis services that reflect its rich cultural heritage and beautiful landscapes. Analysts often focus on palettes inspired by nature."
  },
  { 
    name: "Chile", 
    slug: "chile", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Chile's color analysis services are influenced by its diverse climate and vibrant culture. Analysts often specialize in practical and versatile palettes."
  },
  { 
    name: "Venezuela", 
    slug: "venezuela", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Venezuela offers color analysis services that reflect its lively culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  { 
    name: "Ecuador", 
    slug: "ecuador", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Ecuador's color analysis services are known for their creativity and attention to detail. Analysts often specialize in unique and artistic palettes."
  },
  { 
    name: "Bolivia", 
    slug: "bolivia", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Bolivia offers color analysis services that reflect its rich cultural heritage and beautiful landscapes. Analysts often focus on palettes inspired by nature."
  },
  { 
    name: "Paraguay", 
    slug: "paraguay", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Paraguay's color analysis services are influenced by its diverse culture and modern fashion scene. Analysts often specialize in practical and versatile palettes."
  },
  { 
    name: "Uruguay", 
    slug: "uruguay", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Uruguay offers color analysis services that reflect its rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Costa Rica", 
    slug: "costa-rica", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Costa Rica's color analysis services are known for their creativity and innovation. Analysts often specialize in unique and artistic palettes."
  },
  { 
    name: "Panama", 
    slug: "panama", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Panama offers color analysis services that reflect its vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  { 
    name: "Cuba", 
    slug: "cuba", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Cuba's color analysis services are influenced by its rich history and beautiful landscapes. Analysts often specialize in palettes inspired by the Caribbean."
  },
  { 
    name: "China", 
    slug: "china", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "China offers color analysis services that reflect its diverse culture and modern fashion scene. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Japan", 
    slug: "japan", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Japan's color analysis services are known for their precision and attention to detail. Analysts often specialize in elegant and sophisticated palettes."
  },
  {
    name: "South Korea",
    slug: "south-korea",
    type: "country",
    pricingRange: "₩60,000 - ₩400,000",
    businesses: [
      {
        name: "Vic's Lab",
        description: "Located in Hongdae, offers English-speaking services, known for detailed analysis and product recommendations.",
        website: "http://vicslab.kr/"
      },
      {
        name: "Mood Collect",
        description: "Located in Gangnam, offers packages including makeup tutorials. Note: Strict cancellation policy.",
        instagram: "mood__collect"
      },
      {
        name: "Color Blossom",
        description: "Located in Seongbuk-gu, offers group sessions at a lower price (₩60,000 per person). English-speaking consultant available.",
      },
      {
        name: "Colorsociety",
        description: "Located in Hongdae, known for kind and patient English-speaking consultants.",
      },
      {
        name: "Amoment",
        description: "Located near Hongdae, offers good English translations.",
        instagram: "amoment"
      }
    ],
    description: "South Korea, particularly Seoul, is known for its advanced and detailed personal color analysis services. Many studios offer English-speaking consultants and combine color analysis with makeup tutorials and product recommendations."
  },
  { 
    name: "Korea", 
    slug: "korea", 
    type: "country",
    pricingRange: "₩60,000 - ₩400,000",
    businesses: [
      {
        name: "Vic's Lab",
        description: "Located in Hongdae, offers English-speaking services, known for detailed analysis and product recommendations.",
        website: "http://vicslab.kr/"
      },
      {
        name: "Mood Collect",
        description: "Located in Gangnam, offers packages including makeup tutorials. Note: Strict cancellation policy.",
        instagram: "mood__collect"
      },
      {
        name: "Color Blossom",
        description: "Located in Seongbuk-gu, offers group sessions at a lower price (₩60,000 per person). English-speaking consultant available.",
      },
      {
        name: "Colorsociety",
        description: "Located in Hongdae, known for kind and patient English-speaking consultants.",
      },
      {
        name: "Amoment",
        description: "Located near Hongdae, offers good English translations.",
        instagram: "amoment"
      }
    ],
    description: "South Korea, particularly Seoul, is known for its advanced and detailed personal color analysis services. Many studios offer English-speaking consultants and combine color analysis with makeup tutorials and product recommendations."
  },
  { 
    name: "India", 
    slug: "india", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "India offers color analysis services that reflect its rich cultural heritage and vibrant fashion scene. Analysts often focus on bold and colorful palettes."
  },
  { 
    name: "Indonesia", 
    slug: "indonesia", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Indonesia's color analysis services are influenced by its diverse culture and beautiful landscapes. Analysts often specialize in palettes inspired by nature."
  },
  { 
    name: "Thailand", 
    slug: "thailand", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Thailand offers color analysis services that reflect its vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  { 
    name: "Vietnam", 
    slug: "vietnam", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Vietnam's color analysis services are known for their creativity and innovation. Analysts often specialize in unique and artistic palettes."
  },
  { 
    name: "Malaysia", 
    slug: "malaysia", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Malaysia offers color analysis services that reflect its diverse culture and modern fashion scene. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Singapore", 
    slug: "singapore", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Singapore's color analysis services are known for their precision and attention to detail. Analysts often specialize in elegant and sophisticated palettes."
  },
  { 
    name: "Philippines", 
    slug: "philippines", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "The Philippines offers color analysis services that reflect its vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  { 
    name: "Australia", 
    slug: "australia", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Australia's color analysis services are influenced by its diverse climate and vibrant culture. Analysts often specialize in practical and versatile palettes."
  },
  { 
    name: "New Zealand", 
    slug: "new-zealand", 
    type: "country",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "New Zealand offers color analysis services that reflect its natural beauty and serene landscapes. Analysts often focus on palettes inspired by nature."
  },
  
  // US States
  { name: "California", slug: "california", type: "state", country: "United States" },
  { name: "New York", slug: "new-york-state", type: "state", country: "United States" },
  { name: "Texas", slug: "texas", type: "state", country: "United States" },
  { name: "Florida", slug: "florida", type: "state", country: "United States" },
  { name: "Illinois", slug: "illinois", type: "state", country: "United States" },
  { name: "Pennsylvania", slug: "pennsylvania", type: "state", country: "United States" },
  { name: "Ohio", slug: "ohio", type: "state", country: "United States" },
  { name: "Georgia", slug: "georgia", type: "state", country: "United States" },
  { name: "North Carolina", slug: "north-carolina", type: "state", country: "United States" },
  { name: "Michigan", slug: "michigan", type: "state", country: "United States" },
  { name: "New Jersey", slug: "new-jersey", type: "state", country: "United States" },
  { name: "Virginia", slug: "virginia", type: "state", country: "United States" },
  { name: "Washington", slug: "washington-state", type: "state", country: "United States" },
  { name: "Massachusetts", slug: "massachusetts", type: "state", country: "United States" },
  { name: "Arizona", slug: "arizona", type: "state", country: "United States" },
  { name: "Indiana", slug: "indiana", type: "state", country: "United States" },
  { name: "Tennessee", slug: "tennessee", type: "state", country: "United States" },
  { name: "Missouri", slug: "missouri", type: "state", country: "United States" },
  { name: "Maryland", slug: "maryland", type: "state", country: "United States" },
  { name: "Wisconsin", slug: "wisconsin", type: "state", country: "United States" },
  { name: "Colorado", slug: "colorado", type: "state", country: "United States" },
  { name: "Minnesota", slug: "minnesota", type: "state", country: "United States" },
  { name: "South Carolina", slug: "south-carolina", type: "state", country: "United States" },
  { name: "Alabama", slug: "alabama", type: "state", country: "United States" },
  { name: "Louisiana", slug: "louisiana", type: "state", country: "United States" },
  { name: "Kentucky", slug: "kentucky", type: "state", country: "United States" },
  { name: "Oregon", slug: "oregon", type: "state", country: "United States" },
  { name: "Oklahoma", slug: "oklahoma", type: "state", country: "United States" },
  { name: "Connecticut", slug: "connecticut", type: "state", country: "United States" },
  { name: "Utah", slug: "utah", type: "state", country: "United States" },
  { name: "Iowa", slug: "iowa", type: "state", country: "United States" },
  { name: "Nevada", slug: "nevada", type: "state", country: "United States" },
  { name: "Arkansas", slug: "arkansas", type: "state", country: "United States" },
  { name: "Mississippi", slug: "mississippi", type: "state", country: "United States" },
  { name: "Kansas", slug: "kansas", type: "state", country: "United States" },
  { name: "New Mexico", slug: "new-mexico", type: "state", country: "United States" },
  { name: "Nebraska", slug: "nebraska", type: "state", country: "United States" },
  { name: "West Virginia", slug: "west-virginia", type: "state", country: "United States" },
  { name: "Idaho", slug: "idaho", type: "state", country: "United States" },
  { name: "Hawaii", slug: "hawaii", type: "state", country: "United States" },
  { name: "New Hampshire", slug: "new-hampshire", type: "state", country: "United States" },
  { name: "Maine", slug: "maine", type: "state", country: "United States" },
  { name: "Montana", slug: "montana", type: "state", country: "United States" },
  { name: "Rhode Island", slug: "rhode-island", type: "state", country: "United States" },
  { name: "Delaware", slug: "delaware", type: "state", country: "United States" },
  { name: "South Dakota", slug: "south-dakota", type: "state", country: "United States" },
  { name: "North Dakota", slug: "north-dakota", type: "state", country: "United States" },
  { name: "Alaska", slug: "alaska", type: "state", country: "United States" },
  { name: "District of Columbia", slug: "district-of-columbia", type: "state", country: "United States" },
  { name: "Vermont", slug: "vermont", type: "state", country: "United States" },
  { name: "Wyoming", slug: "wyoming", type: "state", country: "United States" },
  
  // US cities
  { 
    name: "New York", 
    slug: "new-york", 
    type: "city", 
    country: "United States",
    pricingRange: "$200 - $500",
    businesses: [
      {
        name: "Lily's Color Lab",
        description: "Located in K-town. $150 for a 45-minute session. Known for helping clients discover flattering colors.",
        priceRange: "$$"
      },
      {
        name: "Ari Rang Color",
        description: "Located in K-town. Recently went full-time, so more availability expected.",
        website: "https://arirangcolor.com"
      },
      {
        name: "Carol Davidson",
        description: "Offers color analysis and additional services like closet organization.",
        website: "https://caroldavidson.com/"
      },
      {
        name: "House of Colour",
        description: "Popular chain, but some mixed reviews. May lean towards typing people as winters."
      },
      {
        name: "Seklab",
        description: "Highly sought after, but long waitlists. Known for accurate and detailed analysis."
      },
      {
        name: "Rent the Runway Pop-up",
        description: "Occasionally offers free color analysis events. Check for current availability."
      }
    ],
    description: "New York City offers a diverse range of color analysis services, from high-end fashion consultants to more affordable options. The city's fast-paced lifestyle and fashion-forward culture make color analysis a popular choice for professionals and style enthusiasts alike."
  },
  { 
    name: "Los Angeles", 
    slug: "los-angeles", 
    type: "city", 
    country: "United States",
    pricingRange: "$100 - $500",
    businesses: [
      {
        name: "Style by Rayne",
        description: "Highly rated personal shopping and color analysis service. Known for fun and informative color analysis experiences.",
        priceRange: "$$"
      },
      {
        name: "The Ryeon",
        description: "Well-organized and informative personal color consultation services.",
      },
      {
        name: "Colour Me Beautiful LA",
        description: "Affordable color analysis services in the Los Angeles area.",
      },
      {
        name: "Personal Color Lab",
        description: "Makeup artists and personal shoppers offering worthwhile color consultations.",
      },
      {
        name: "Colors By Midnight",
        description: "Transformative approach to fashion and self-presentation through color analysis.",
      },
      {
        name: "Elevateiostyle",
        description: "Highly praised color analysis experience.",
      },
      {
        name: "iColorMe Studio",
        description: "Color consultation services that provide a better understanding of personal colors.",
      },
      {
        name: "Alison Deyette Styling Services",
        description: "Accomplished stylist offering personal shopping services.",
        priceRange: "$$"
      }
    ],
    description: "Los Angeles offers a diverse range of color analysis services, from personal shoppers to specialized color consultants. The city's fashion-forward culture and entertainment industry influence make it a hub for innovative and personalized color analysis experiences. Whether you're looking for an affordable option or a high-end styling service, LA has options to suit various preferences and budgets."
  },
  { 
    name: "Chicago", 
    slug: "chicago", 
    type: "city", 
    country: "United States",
    pricingRange: "$125 - $350",
    businesses: [
      {
        name: "Beth Soria",
        description: "Highly recommended color analysis service. Costs $300 for a session.",
        website: "https://linktr.ee/bethsoria?utm_source=linktree_profile_share&ltsid=cf0bfd3e-b17c-4417-aedb-35d345d9c34c"
      },
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour",
      "ColorStyle Studio"
    ],
    description: "Chicago's color analysis scene blends Midwestern practicality with urban sophistication. Services range from personal consultations to group workshops, catering to the city's diverse population and varying seasons."
  },
  { 
    name: "San Francisco", 
    slug: "san-francisco", 
    type: "city", 
    country: "United States",
    pricingRange: "$175 - $450",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour",
      "ColorStyle Studio",
      {
        name: "Kerry Jones",
        description: "Recommended by multiple users.",
        website: "https://kerryjones.com"
      },
      {
        name: "K Griffin Style",
        description: "Highly recommended color analyst. Known for detailed analysis of neutrals, undertones, and jewelry options. Also provides hair color advice.",
        website: "https://kgriffinstyle.com"
      }
    ],
    description: "San Francisco's tech-savvy population has embraced digital color analysis tools, while still appreciating in-person consultations. The city's foggy climate and casual-chic style influence color choices and analysis methods."
  },
  { 
    name: "Miami", 
    slug: "miami", 
    type: "city", 
    country: "United States",
    pricingRange: "$100 - $300",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour",
      "ColorStyle Studio"
    ],
    description: "Miami's vibrant culture and tropical climate are reflected in its color analysis services. Analysts often specialize in bright, bold palettes suitable for the city's lively fashion scene and year-round sunshine."
  },
  { 
    name: "Houston", 
    slug: "houston", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "Houston's color analysis services reflect the city's diverse population and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Phoenix", 
    slug: "phoenix", 
    type: "city", 
    country: "United States",
    pricingRange: "$110 - $300",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Phoenix's color analysis services are influenced by the desert climate and sunny weather. Analysts often specialize in warm and earthy palettes that complement the natural surroundings."
  },
  { 
    name: "Philadelphia", 
    slug: "philadelphia", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "Philadelphia's color analysis scene blends historical charm with modern trends. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  { 
    name: "San Antonio", 
    slug: "san-antonio", 
    type: "city", 
    country: "United States",
    pricingRange: "$100 - $300",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "San Antonio's color analysis services reflect the city's rich cultural heritage and warm climate. Analysts often focus on vibrant and festive palettes suitable for the lively local fashion scene."
  },
  { 
    name: "San Diego", 
    slug: "san-diego", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "San Diego's color analysis services are influenced by the coastal climate and laid-back lifestyle. Analysts often specialize in fresh and breezy palettes that reflect the city's beach culture."
  },
  { 
    name: "Dallas", 
    slug: "dallas", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      {
        name: "Karen Konecny Beauty",
        description: "You can do a virtual or in-studio color analysis at Karen Konecny Beauty in Dallas Victory Park",
        website: "https://karenkonecnybeauty.com"
      },
      {
        name: "Colorpolitan",
        description: "Comprehensive Personal Color Analysis session using calibrated professional Color Drapes and the Gold Standard TCI.",
        website: "https://colorpolitan.com/personalcoloranalysis"
      },
      "House of Colour"
    ],
    description: "Dallas's color analysis scene combines Southern charm with urban sophistication. Services range from personal consultations to corporate workshops, catering to the city's diverse clientele."
  },
  { 
    name: "San Jose", 
    slug: "san-jose", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "San Jose's tech-savvy population has embraced digital color analysis tools, while still appreciating in-person consultations. The city's innovative spirit influences color choices and analysis methods."
  },
  { 
    name: "Austin", 
    slug: "austin", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "Austin's color analysis services reflect the city's eclectic and creative vibe. Analysts often focus on unique and artistic palettes that cater to the local music and arts scene."
  },
  { 
    name: "Columbus", 
    slug: "columbus", 
    type: "city", 
    country: "United States",
    pricingRange: "$110 - $300",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Columbus's color analysis scene blends Midwestern practicality with modern trends. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  { 
    name: "Fort Worth", 
    slug: "fort-worth", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Fort Worth's color analysis services reflect the city's Western heritage and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Indianapolis", 
    slug: "indianapolis", 
    type: "city", 
    country: "United States",
    pricingRange: "$110 - $300",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Indianapolis's color analysis scene blends Midwestern practicality with urban sophistication. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  { 
    name: "Charlotte", 
    slug: "charlotte", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Charlotte's color analysis services reflect the city's Southern charm and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Seattle", 
    slug: "seattle", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $400",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "Seattle's color analysis services are influenced by the city's tech-savvy population and rainy climate. Analysts often specialize in fresh and vibrant palettes that brighten up the city's gray days."
  },
  { 
    name: "Denver", 
    slug: "denver", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Denver's color analysis services reflect the city's outdoor lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Washington D.C.", 
    slug: "washington-dc", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "Washington D.C.'s color analysis scene blends political sophistication with modern trends. Services cater to a wide range of clients, from personal consultations to corporate workshops."
  },
  { 
    name: "Boston", 
    slug: "boston", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $400",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "Boston's color analysis services reflect the city's historical charm and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Las Vegas", 
    slug: "las-vegas", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Las Vegas's color analysis services are influenced by the city's vibrant nightlife and desert climate. Analysts often specialize in bold and dramatic palettes suitable for the city's lively fashion scene."
  },
  { 
    name: "Atlanta", 
    slug: "atlanta", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "Atlanta's color analysis services reflect the city's Southern charm and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "New Orleans", 
    slug: "new-orleans", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "New Orleans's color analysis services are influenced by the city's vibrant culture and warm climate. Analysts often specialize in bold and festive palettes suitable for the lively local fashion scene."
  },
  { 
    name: "Portland", 
    slug: "portland", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $400",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "Portland's color analysis services reflect the city's eclectic and creative vibe. Analysts often focus on unique and artistic palettes that cater to the local arts scene."
  },
  { 
    name: "Nashville", 
    slug: "nashville", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Nashville's color analysis services are influenced by the city's vibrant music scene and Southern charm. Analysts often specialize in bold and stylish palettes suitable for the lively local fashion scene."
  },
  { 
    name: "Detroit", 
    slug: "detroit", 
    type: "city", 
    country: "United States",
    pricingRange: "$110 - $300",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Detroit's color analysis scene blends industrial practicality with modern trends. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  { 
    name: "Baltimore", 
    slug: "baltimore", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Baltimore's color analysis services reflect the city's historical charm and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "LA", 
    slug: "la", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      {
        name: "Style by Rayne",
        description: "Highly rated personal shopping and color analysis service. Known for fun and informative color analysis experiences.",
        priceRange: "$$"
      },
      {
        name: "The Ryeon",
        description: "Well-organized and informative personal color consultation services.",
      },
      {
        name: "Colour Me Beautiful LA",
        description: "Affordable color analysis services in the Los Angeles area.",
      },
      {
        name: "Personal Color Lab",
        description: "Makeup artists and personal shoppers offering worthwhile color consultations.",
      },
      {
        name: "Colors By Midnight",
        description: "Transformative approach to fashion and self-presentation through color analysis.",
      },
      {
        name: "Elevateiostyle",
        description: "Highly praised color analysis experience.",
      },
      {
        name: "iColorMe Studio",
        description: "Color consultation services that provide a better understanding of personal colors.",
      },
      {
        name: "Alison Deyette Styling Services",
        description: "Accomplished stylist offering personal shopping services.",
        priceRange: "$$"
      }
    ],
    description: "In Los Angeles, color analysis services cater to both the entertainment industry and everyday fashion lovers. The sunny climate influences color choices, with many analysts specializing in vibrant, camera-ready palettes."
  },
  { 
    name: "NYC", 
    slug: "nyc", 
    type: "city", 
    country: "United States",
    pricingRange: "$160 - $450",
    businesses: [
      {
        name: "Lily's Color Lab",
        description: "Located in K-town. $150 for a 45-minute session. Known for helping clients discover flattering colors.",
        priceRange: "$$"
      },
      {
        name: "Ari Rang Color",
        description: "Located in K-town. Recently went full-time, so more availability expected.",
        website: "https://arirangcolor.com"
      },
      {
        name: "Carol Davidson",
        description: "Offers color analysis and additional services like closet organization.",
        website: "https://caroldavidson.com/"
      },
      {
        name: "House of Colour",
        description: "Popular chain, but some mixed reviews. May lean towards typing people as winters."
      },
      {
        name: "Seklab",
        description: "Highly sought after, but long waitlists. Known for accurate and detailed analysis."
      },
      {
        name: "Rent the Runway Pop-up",
        description: "Occasionally offers free color analysis events. Check for current availability."
      }
    ],
    description: "New York City's color analysis scene is as diverse as its population. Services range from high-end fashion consultations to more affordable options, catering to the city's fast-paced lifestyle and varying seasons."
  },
  { 
    name: "SF", 
    slug: "sf", 
    type: "city", 
    country: "United States",
    pricingRange: "$175 - $450",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour",
      "ColorStyle Studio"
    ],
    description: "San Francisco's tech-savvy population has embraced digital color analysis tools, while still appreciating in-person consultations. The city's foggy climate and casual-chic style influence color choices and analysis methods."
  },
  { 
    name: "DC", 
    slug: "dc", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "Washington D.C.'s color analysis scene blends political sophistication with modern trends. Services cater to a wide range of clients, from personal consultations to corporate workshops."
  },
  { 
    name: "Washington", 
    slug: "washington", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "Washington D.C.'s color analysis scene blends political sophistication with modern trends. Services cater to a wide range of clients, from personal consultations to corporate workshops."
  },
  { 
    name: "Philly", 
    slug: "philly", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "Philadelphia's color analysis scene blends historical charm with modern trends. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  { 
    name: "Minneapolis", 
    slug: "minneapolis", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Minneapolis's color analysis services reflect the city's Midwestern practicality and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "St. Louis", 
    slug: "st-louis", 
    type: "city", 
    country: "United States",
    pricingRange: "$110 - $300",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "St. Louis's color analysis scene blends Midwestern practicality with urban sophistication. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  { 
    name: "Kansas City", 
    slug: "kansas-city", 
    type: "city", 
    country: "United States",
    pricingRange: "$110 - $300",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Kansas City's color analysis services reflect the city's Midwestern practicality and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Cleveland", 
    slug: "cleveland", 
    type: "city", 
    country: "United States",
    pricingRange: "$110 - $300",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Cleveland's color analysis scene blends Midwestern practicality with urban sophistication. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  { 
    name: "Pittsburgh", 
    slug: "pittsburgh", 
    type: "city", 
    country: "United States",
    pricingRange: "$110 - $300",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Pittsburgh's color analysis services reflect the city's industrial heritage and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Cincinnati", 
    slug: "cincinnati", 
    type: "city", 
    country: "United States",
    pricingRange: "$110 - $300",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Cincinnati's color analysis scene blends Midwestern practicality with urban sophistication. Services cater to a wide range of clients, from personal consultations to group workshops."
  },
  { 
    name: "Orlando", 
    slug: "orlando", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Orlando's color analysis services are influenced by the city's vibrant tourism industry and sunny climate. Analysts often specialize in bright and cheerful palettes suitable for the lively local fashion scene."
  },
  { 
    name: "Tampa", 
    slug: "tampa", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Tampa's color analysis services reflect the city's coastal climate and vibrant culture. Analysts often focus on fresh and breezy palettes suitable for the varying climate."
  },
  { 
    name: "Raleigh", 
    slug: "raleigh", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Raleigh's color analysis services reflect the city's Southern charm and modern trends. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Salt Lake City", 
    slug: "salt-lake-city", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Salt Lake City's color analysis services are influenced by the city's outdoor lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Sacramento", 
    slug: "sacramento", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      {
        name: "Color Harmonies",
        description: "Joan Jackson offers color analysis services in Placerville. Has been in business for many years.",
        website: "https://colorharmonies.com"
      }
    ],
    description: "Sacramento's color analysis services reflect the city's diverse population and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Honolulu", 
    slug: "honolulu", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Honolulu's color analysis services are influenced by the city's tropical climate and vibrant culture. Analysts often specialize in bright and cheerful palettes suitable for the lively local fashion scene."
  },
  { 
    name: "Anchorage", 
    slug: "anchorage", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Anchorage's color analysis services reflect the city's unique climate and natural beauty. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Albuquerque", 
    slug: "albuquerque", 
    type: "city", 
    country: "United States",
    pricingRange: "$100 - $300",
    businesses: [],
    description: "Albuquerque's color analysis services reflect the city's unique blend of cultural influences and natural beauty. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Houston", 
    slug: "houston", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Houston's color analysis services are influenced by the city's diverse population and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Phoenix", 
    slug: "phoenix", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Phoenix's color analysis services reflect the city's desert climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Philadelphia", 
    slug: "philadelphia", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Philadelphia's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "San Antonio", 
    slug: "san-antonio", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "San Antonio's color analysis services reflect the city's unique blend of cultural influences and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "San Diego", 
    slug: "san-diego", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "San Diego's color analysis services are influenced by the city's coastal climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Dallas", 
    slug: "dallas", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Dallas's color analysis services reflect the city's unique blend of cultural influences and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "San Jose", 
    slug: "san-jose", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "San Jose's color analysis services are influenced by the city's tech-driven culture and vibrant lifestyle. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Austin", 
    slug: "austin", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Austin's color analysis services reflect the city's unique blend of cultural influences and vibrant music scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Columbus", 
    slug: "columbus", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Columbus's color analysis services are influenced by the city's diverse population and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Fort Worth", 
    slug: "fort-worth", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Fort Worth's color analysis services reflect the city's unique blend of cultural influences and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Indianapolis", 
    slug: "indianapolis", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Indianapolis's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Charlotte", 
    slug: "charlotte", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Charlotte's color analysis services reflect the city's unique blend of cultural influences and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Seattle", 
    slug: "seattle", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Seattle's color analysis services are influenced by the city's coastal climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Denver", 
    slug: "denver", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Denver's color analysis services reflect the city's unique blend of cultural influences and vibrant outdoor lifestyle. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Washington D.C.", 
    slug: "washington-dc", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Washington D.C.'s color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Boston", 
    slug: "boston", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Boston's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Las Vegas", 
    slug: "las-vegas", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Las Vegas's color analysis services are influenced by the city's vibrant nightlife and entertainment scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Atlanta", 
    slug: "atlanta", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Atlanta's color analysis services reflect the city's unique blend of cultural influences and vibrant music scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "New Orleans", 
    slug: "new-orleans", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "New Orleans's color analysis services are influenced by the city's vibrant culture and warm climate. Analysts often specialize in bold and festive palettes suitable for the lively local fashion scene."
  },
  { 
    name: "Portland", 
    slug: "portland", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Portland's color analysis services reflect the city's unique blend of cultural influences and vibrant outdoor lifestyle. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Nashville", 
    slug: "nashville", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Nashville's color analysis services are influenced by the city's vibrant music scene and rich cultural heritage. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Detroit", 
    slug: "detroit", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Detroit's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Baltimore", 
    slug: "baltimore", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Baltimore's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "LA", 
    slug: "la", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "LA's color analysis services reflect the city's unique blend of cultural influences and vibrant entertainment scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "NYC", 
    slug: "nyc", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "NYC's color analysis services are influenced by the city's diverse population and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "SF", 
    slug: "sf", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "SF's color analysis services reflect the city's unique blend of cultural influences and vibrant tech scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "DC", 
    slug: "dc", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "DC's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Washington", 
    slug: "washington", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Washington's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Philly", 
    slug: "philly", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Philly's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Minneapolis", 
    slug: "minneapolis", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Minneapolis's color analysis services reflect the city's unique blend of cultural influences and vibrant outdoor lifestyle. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "St. Louis", 
    slug: "st-louis", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "St. Louis's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Kansas City", 
    slug: "kansas-city", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Kansas City's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Cleveland", 
    slug: "cleveland", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Cleveland's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Pittsburgh", 
    slug: "pittsburgh", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Pittsburgh's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Cincinnati", 
    slug: "cincinnati", 
    type: "city", 
    country: "United States",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Cincinnati's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Orlando", 
    slug: "orlando", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Orlando's color analysis services reflect the city's unique blend of cultural influences and vibrant entertainment scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Tampa", 
    slug: "tampa", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Tampa's color analysis services are influenced by the city's coastal climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Raleigh", 
    slug: "raleigh", 
    type: "city", 
    country: "United States",
    pricingRange: "$140 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Raleigh's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Salt Lake City", 
    slug: "salt-lake-city", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Salt Lake City's color analysis services are influenced by the city's outdoor lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Sacramento", 
    slug: "sacramento", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Sacramento's color analysis services reflect the city's unique blend of cultural influences and vibrant history. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Honolulu", 
    slug: "honolulu", 
    type: "city", 
    country: "United States",
    pricingRange: "$150 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Honolulu's color analysis services are influenced by the city's tropical climate and vibrant culture. Analysts often specialize in bright and cheerful palettes suitable for the lively local fashion scene."
  },
  { 
    name: "Anchorage", 
    slug: "anchorage", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Anchorage's color analysis services reflect the city's unique climate and natural beauty. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Tucson", 
    slug: "tucson", 
    type: "city", 
    country: "United States",
    pricingRange: "$110 - $320",
    businesses: [],
    description: "Tucson's color analysis services reflect the city's desert climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Fresno", 
    slug: "fresno", 
    type: "city", 
    country: "United States",
    pricingRange: "$120 - $330",
    businesses: [],
    description: "Fresno's color analysis services are influenced by the city's agricultural roots and diverse population. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },

  
  // Canadian cities
  { 
    name: "Toronto", 
    slug: "toronto", 
    type: "city", 
    country: "Canada",
    pricingRange: "$130 - $350",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "Toronto's color analysis services reflect the city's diverse culture and vibrant fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Montreal", 
    slug: "montreal", 
    type: "city", 
    country: "Canada",
    pricingRange: "$120 - $340",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Montreal's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Vancouver", 
    slug: "vancouver", 
    type: "city", 
    country: "Canada",
    pricingRange: "$140 - $360",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Vancouver's color analysis services reflect the city's coastal climate and diverse population. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Calgary", 
    slug: "calgary", 
    type: "city", 
    country: "Canada",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Calgary's color analysis services are influenced by the city's outdoor lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Edmonton", 
    slug: "edmonton", 
    type: "city", 
    country: "Canada",
    pricingRange: "$120 - $340",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Edmonton's color analysis services reflect the city's unique climate and diverse population. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Ottawa", 
    slug: "ottawa", 
    type: "city", 
    country: "Canada",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Ottawa's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Winnipeg", 
    slug: "winnipeg", 
    type: "city", 
    country: "Canada",
    pricingRange: "$120 - $340",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Winnipeg's color analysis services reflect the city's unique climate and diverse population. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Quebec City", 
    slug: "quebec-city", 
    type: "city", 
    country: "Canada",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Quebec City's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Hamilton", 
    slug: "hamilton", 
    type: "city", 
    country: "Canada",
    pricingRange: "$120 - $340",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Hamilton's color analysis services reflect the city's industrial roots and diverse population. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Halifax", 
    slug: "halifax", 
    type: "city", 
    country: "Canada",
    pricingRange: "$130 - $350",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Halifax's color analysis services are influenced by the city's coastal climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  
  // European cities
  { 
    name: "London", 
    slug: "london", 
    type: "city", 
    country: "United Kingdom",
    pricingRange: "$150 - $450",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      "House of Colour"
    ],
    description: "London's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Paris", 
    slug: "paris", 
    type: "city", 
    country: "France",
    pricingRange: "$200 - $500",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Paris is known for its high-end fashion and sophisticated color analysis services. Analysts often focus on chic and elegant palettes that reflect the city's style."
  },
  { 
    name: "Berlin", 
    slug: "berlin", 
    type: "city", 
    country: "Germany",
    pricingRange: "$100 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Berlin's color analysis services reflect the city's modern and diverse culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Madrid", 
    slug: "madrid", 
    type: "city", 
    country: "Spain",
    pricingRange: "$150 - $450",
    businesses: [],
    description: "Madrid's color analysis services are influenced by the city's vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  { 
    name: "Rome", 
    slug: "rome", 
    type: "city", 
    country: "Italy",
    pricingRange: "$200 - $500",
    businesses: [],
    description: "Rome's color analysis services reflect the city's rich history and elegant fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Amsterdam", 
    slug: "amsterdam", 
    type: "city", 
    country: "Netherlands",
    pricingRange: "$150 - $450",
    businesses: [],
    description: "Amsterdam's color analysis services are influenced by the city's modern and diverse culture. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Vienna", 
    slug: "vienna", 
    type: "city", 
    country: "Austria",
    pricingRange: "$150 - $450",
    businesses: [],
    description: "Vienna's color analysis services reflect the city's rich cultural heritage and elegant fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Moscow", 
    slug: "moscow", 
    type: "city", 
    country: "Russia",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Moscow's color analysis services are known for their bold and dramatic approach. Analysts often specialize in vibrant and striking palettes."
  },
  { 
    name: "Stockholm", 
    slug: "stockholm", 
    type: "city", 
    country: "Sweden",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Stockholm's color analysis services are known for their simplicity and elegance. Analysts often specialize in clean and modern palettes."
  },
  { 
    name: "Prague", 
    slug: "prague", 
    type: "city", 
    country: "Czech Republic",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Prague's color analysis services are known for their creativity and attention to detail. Analysts often specialize in unique and artistic palettes."
  },
  { 
    name: "Munich", 
    slug: "munich", 
    type: "city", 
    country: "Germany",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Munich's color analysis services reflect the city's modern and diverse culture. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Barcelona", 
    slug: "barcelona", 
    type: "city", 
    country: "Spain",
    pricingRange: "$150 - $450",
    businesses: [],
    description: "Barcelona's color analysis services are influenced by the city's vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  { 
    name: "Milan", 
    slug: "milan", 
    type: "city", 
    country: "Italy",
    pricingRange: "$200 - $500",
    businesses: [],
    description: "Milan's color analysis services reflect the city's rich history and elegant fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Copenhagen", 
    slug: "copenhagen", 
    type: "city", 
    country: "Denmark",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Copenhagen's color analysis services are known for their simplicity and elegance. Analysts often specialize in clean and modern palettes."
  },
  { 
    name: "Dublin", 
    slug: "dublin", 
    type: "city", 
    country: "Ireland",
    pricingRange: "$150 - $450",
    businesses: [],
    description: "Dublin's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Brussels", 
    slug: "brussels", 
    type: "city", 
    country: "Belgium",
    pricingRange: "$150 - $450",
    businesses: [],
    description: "Brussels' color analysis services are influenced by the city's modern and diverse culture. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Lisbon", 
    slug: "lisbon", 
    type: "city", 
    country: "Portugal",
    pricingRange: "$150 - $450",
    businesses: [],
    description: "Lisbon's color analysis services are influenced by the city's vibrant culture and sunny climate. Analysts often focus on bright and cheerful palettes."
  },
  { 
    name: "Athens", 
    slug: "athens", 
    type: "city", 
    country: "Greece",
    pricingRange: "$150 - $450",
    businesses: [],
    description: "Athens' color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Warsaw", 
    slug: "warsaw", 
    type: "city", 
    country: "Poland",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Warsaw's color analysis services reflect the city's diverse culture and modern fashion scene. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Budapest", 
    slug: "budapest", 
    type: "city", 
    country: "Hungary",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Budapest's color analysis services reflect the city's rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Helsinki", 
    slug: "helsinki", 
    type: "city", 
    country: "Finland",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Helsinki's color analysis services reflect the city's unique blend of tradition and modernity. Analysts often focus on palettes that are both practical and stylish."
  },
  { 
    name: "Oslo", 
    slug: "oslo", 
    type: "city", 
    country: "Norway",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Oslo's color analysis services reflect the city's unique blend of tradition and modernity. Analysts often focus on palettes that are both practical and stylish."
  },
  { 
    name: "Zurich", 
    slug: "zurich", 
    type: "city", 
    country: "Switzerland",
    pricingRange: "$150 - $450",
    businesses: [],
    description: "Zurich's color analysis services reflect the city's rich cultural heritage and elegant fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Edinburgh", 
    slug: "edinburgh", 
    type: "city", 
    country: "United Kingdom",
    pricingRange: "$150 - $450",
    businesses: [],
    description: "Edinburgh's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Hamburg", 
    slug: "hamburg", 
    type: "city", 
    country: "Germany",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Hamburg's color analysis services reflect the city's modern and diverse culture. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Frankfurt", 
    slug: "frankfurt", 
    type: "city", 
    country: "Germany",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Frankfurt's color analysis services reflect the city's modern and diverse culture. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Lyon", 
    slug: "lyon", 
    type: "city", 
    country: "France",
    pricingRange: "$200 - $500",
    businesses: [],
    description: "Lyon's color analysis services reflect the city's rich history and elegant fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Marseille", 
    slug: "marseille", 
    type: "city", 
    country: "France",
    pricingRange: "$200 - $500",
    businesses: [],
    description: "Marseille's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Naples", 
    slug: "naples", 
    type: "city", 
    country: "Italy",
    pricingRange: "$200 - $500",
    businesses: [],
    description: "Naples' color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Venice", 
    slug: "venice", 
    type: "city", 
    country: "Italy",
    pricingRange: "$200 - $500",
    businesses: [],
    description: "Venice's color analysis services reflect the city's rich history and elegant fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Krakow", 
    slug: "krakow", 
    type: "city", 
    country: "Poland",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Krakow's color analysis services reflect the city's diverse culture and modern fashion scene. Analysts often focus on practical and versatile palettes."
  },
  { 
    name: "Bucharest", 
    slug: "bucharest", 
    type: "city", 
    country: "Romania",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Bucharest's color analysis services reflect the city's rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Sofia", 
    slug: "sofia", 
    type: "city", 
    country: "Bulgaria",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Sofia's color analysis services reflect the city's rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Belgrade", 
    slug: "belgrade", 
    type: "city", 
    country: "Serbia",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Belgrade's color analysis services reflect the city's rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  { 
    name: "Kiev", 
    slug: "kiev", 
    type: "city", 
    country: "Ukraine",
    pricingRange: "$100 - $400",
    businesses: [],
    description: "Kiev's color analysis services reflect the city's rich cultural heritage and modern fashion scene. Analysts often focus on classic and timeless palettes."
  },
  
  // Asian cities
  { 
    name: "Tokyo", 
    slug: "tokyo", 
    type: "city", 
    country: "Japan",
    pricingRange: "$100 - $300",
    businesses: [
      {
        name: "Best Colors 4 You",
        description: "Fully accredited analyst using a variation of the 12-season SciART system. Teaches color analysis at a design college. Speaks perfect English.",
        website: "https://bestcolors4you.com/englishmenu/"
      },
      {
        name: "Color Me Tokyo",
        description: "Higher-end option, but may be expensive compared to others."
      },
      {
        name: "Tatsumi",
        description: "Another option, but check availability as they may have limited hours."
      }
    ],
    description: "Tokyo offers a range of color analysis services, from affordable to high-end options. Many analysts are well-trained in modern color systems and some offer services in English. Prices are generally reasonable compared to European and US rates."
  },
  {
    name: "Seoul",
    slug: "seoul",
    type: "city",
    country: "South Korea",
    pricingRange: "₩60,000 - ₩400,000",
    businesses: [
      {
        name: "Vic's Lab",
        description: "Located in Hongdae, offers English-speaking services, known for detailed analysis and product recommendations.",
        website: "http://vicslab.kr/"
      },
      {
        name: "Mood Collect",
        description: "Located in Gangnam, offers packages including makeup tutorials. Note: Strict cancellation policy.",
        instagram: "mood__collect"
      },
      {
        name: "Color Blossom",
        description: "Located in Seongbuk-gu, offers group sessions at a lower price (₩60,000 per person). English-speaking consultant available.",
      },
      {
        name: "Colorsociety",
        description: "Located in Hongdae, known for kind and patient English-speaking consultants.",
      },
      {
        name: "Amoment",
        description: "Located near Hongdae, offers good English translations.",
        instagram: "amoment"
      }
    ],
    description: "Seoul is a hub for personal color analysis in South Korea. The city offers a variety of options from budget-friendly group sessions to high-end individual consultations. Many services include detailed makeup advice and product recommendations tailored to your color palette. English-speaking options are widely available, making it accessible for international visitors."
  },
  { 
    name: "Beijing", 
    slug: "beijing", 
    type: "city", 
    country: "China",
    pricingRange: "$130 - $420",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Beijing's color analysis services reflect the city's rich history and modern development. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Shanghai", 
    slug: "shanghai", 
    type: "city", 
    country: "China",
    pricingRange: "$140 - $430",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Shanghai's color analysis services are influenced by the city's cosmopolitan lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Mumbai", 
    slug: "mumbai", 
    type: "city", 
    country: "India",
    pricingRange: "$120 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Mumbai's color analysis services reflect the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Bangkok", 
    slug: "bangkok", 
    type: "city", 
    country: "Thailand",
    pricingRange: "$110 - $390",
    businesses: [
      {
        name: "Color Me Beautiful",
        description: "Offers a range of services from personal color analysis to group workshops.",
        website: "https://colormebeautiful.com"
      },
      {
        name: "Spiral Color",
        description: "Great personal style, great prices, and great service.",
        instagram: "https://www.instagram.com/p/C47Uj7QPtwz/"
      }
    ],
    description: "Bangkok's color analysis services are influenced by the city's vibrant culture and tropical climate. Analysts often focus on bright and cheerful palettes suitable for the lively local fashion scene."
  },
  { 
    name: "Singapore", 
    slug: "singapore", 
    type: "city", 
    country: "Singapore",
    pricingRange: "$150 - $450",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Singapore's color analysis services reflect the city's blend of cultural influences and modern lifestyle. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Hong Kong", 
    slug: "hong-kong", 
    type: "city", 
    country: "China",
    pricingRange: "$140 - $430",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Hong Kong's color analysis services are influenced by the city's cosmopolitan lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Jakarta", 
    slug: "jakarta", 
    type: "city", 
    country: "Indonesia",
    pricingRange: "$120 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Jakarta's color analysis services reflect the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Manila", 
    slug: "manila", 
    type: "city", 
    country: "Philippines",
    pricingRange: "$110 - $390",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Manila's color analysis services are influenced by the city's vibrant culture and tropical climate. Analysts often focus on bright and cheerful palettes suitable for the lively local fashion scene."
  },
  
  // Central and South American cities
  { 
    name: "Mexico City", 
    slug: "mexico-city", 
    type: "city", 
    country: "Mexico",
    pricingRange: "$130 - $420",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Mexico City's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "São Paulo", 
    slug: "sao-paulo", 
    type: "city", 
    country: "Brazil",
    pricingRange: "$140 - $430",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "São Paulo's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Rio de Janeiro", 
    slug: "rio-de-janeiro", 
    type: "city", 
    country: "Brazil",
    pricingRange: "$130 - $420",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Rio de Janeiro's color analysis scene reflects the city's vibrant beach culture and lively fashion. Analysts often focus on bright, tropical palettes suitable for the warm climate."
  },
  { 
    name: "Salvador", 
    slug: "salvador", 
    type: "city", 
    country: "Brazil",
    pricingRange: "$120 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Salvador's color analysis services are influenced by the city's rich Afro-Brazilian heritage. Analysts often incorporate bold, vibrant colors reflecting the local culture and tropical environment."
  },
  { 
    name: "Brasília", 
    slug: "brasilia", 
    type: "city", 
    country: "Brazil",
    pricingRange: "$130 - $410",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Brasília's color analysis scene blends modern aesthetics with natural influences. Analysts often focus on sophisticated palettes that complement the city's unique architecture and landscape."
  },
  { 
    name: "Fortaleza", 
    slug: "fortaleza", 
    type: "city", 
    country: "Brazil",
    pricingRange: "$110 - $390",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Fortaleza's color analysis services reflect the city's coastal charm. Analysts often focus on fresh, beachy palettes suitable for the tropical climate and relaxed lifestyle."
  },
  { 
    name: "Belo Horizonte", 
    slug: "belo-horizonte", 
    type: "city", 
    country: "Brazil",
    pricingRange: "$120 - $400",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Belo Horizonte's color analysis scene combines urban sophistication with natural beauty. Analysts often focus on versatile palettes that reflect the city's diverse cultural influences."
  },
  { 
    name: "Manaus", 
    slug: "manaus", 
    type: "city", 
    country: "Brazil",
    pricingRange: "$110 - $380",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Manaus' color analysis services are influenced by the surrounding Amazon rainforest. Analysts often incorporate lush, natural palettes inspired by the unique ecosystem."
  },
  { 
    name: "Buenos Aires", 
    slug: "buenos-aires", 
    type: "city", 
    country: "Argentina",
    pricingRange: "$130 - $420",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Buenos Aires' color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Bogotá", 
    slug: "bogota", 
    type: "city", 
    country: "Colombia",
    pricingRange: "$120 - $410",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Bogotá's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Lima", 
    slug: "lima", 
    type: "city", 
    country: "Peru",
    pricingRange: "$110 - $390",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Lima's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Santiago", 
    slug: "santiago", 
    type: "city", 
    country: "Chile",
    pricingRange: "$120 - $410",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Santiago's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Rio de Janeiro", 
    slug: "rio-de-janeiro", 
    type: "city", 
    country: "Brazil",
    pricingRange: "$140 - $430",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Rio de Janeiro's color analysis services reflect the city's vibrant culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Caracas", 
    slug: "caracas", 
    type: "city", 
    country: "Venezuela",
    pricingRange: "$110 - $390",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Caracas' color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Quito", 
    slug: "quito", 
    type: "city", 
    country: "Ecuador",
    pricingRange: "$120 - $410",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Quito's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Panama City", 
    slug: "panama-city", 
    type: "city", 
    country: "Panama",
    pricingRange: "$130 - $420",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Panama City's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  // Australian and New Zealand cities
  { 
    name: "Sydney", 
    slug: "sydney", 
    type: "city", 
    country: "Australia",
    pricingRange: "$150 - $450",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Sydney's color analysis services reflect the city's coastal lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Melbourne", 
    slug: "melbourne", 
    type: "city", 
    country: "Australia",
    pricingRange: "$150 - $450",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Melbourne's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Brisbane", 
    slug: "brisbane", 
    type: "city", 
    country: "Australia",
    pricingRange: "$140 - $430",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Brisbane's color analysis services reflect the city's tropical climate and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Perth", 
    slug: "perth", 
    type: "city", 
    country: "Australia",
    pricingRange: "$140 - $430",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Perth's color analysis services are influenced by the city's coastal lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Auckland", 
    slug: "auckland", 
    type: "city", 
    country: "New Zealand",
    pricingRange: "$150 - $450",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Auckland's color analysis services reflect the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },

  { 
    name: "Kuala Lumpur", 
    slug: "kuala-lumpur", 
    type: "city", 
    country: "Malaysia",
    pricingRange: "$130 - $420",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Kuala Lumpur's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Taipei", 
    slug: "taipei", 
    type: "city", 
    country: "Taiwan",
    pricingRange: "$140 - $430",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Taipei's color analysis services reflect the city's blend of traditional and modern influences. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Hanoi", 
    slug: "hanoi", 
    type: "city", 
    country: "Vietnam",
    pricingRange: "$120 - $410",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Hanoi's color analysis services are influenced by the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Ho Chi Minh City", 
    slug: "ho-chi-minh-city", 
    type: "city", 
    country: "Vietnam",
    pricingRange: "$120 - $410",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Ho Chi Minh City's color analysis services reflect the city's dynamic fashion scene and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Guangzhou", 
    slug: "guangzhou", 
    type: "city", 
    country: "China",
    pricingRange: "$130 - $420",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Guangzhou's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Shenzhen", 
    slug: "shenzhen", 
    type: "city", 
    country: "China",
    pricingRange: "$130 - $420",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Shenzhen's color analysis services reflect the city's modern development and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Osaka", 
    slug: "osaka", 
    type: "city", 
    country: "Japan",
    pricingRange: "$140 - $430",
    businesses: [
      {
        name: "Best Colors 4 You",
        description: "Fully accredited analyst using a variation of the 12-season SciART system. Teaches color analysis at a design college. Speaks perfect English.",
        website: "https://bestcolors4you.com/englishmenu/"
      },
      {
        name: "Color Me Tokyo",
        description: "Higher-end option, but may be expensive compared to others."
      },
      {
        name: "Tatsumi",
        description: "Another option, but check availability as they may have limited hours."
      }
    ],
    description: "Osaka's color analysis services are influenced by the city's blend of traditional and modern influences. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Delhi", 
    slug: "delhi", 
    type: "city", 
    country: "India",
    pricingRange: "$120 - $410",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Delhi's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Bangalore", 
    slug: "bangalore", 
    type: "city", 
    country: "India",
    pricingRange: "$120 - $410",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Bangalore's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Kolkata", 
    slug: "kolkata", 
    type: "city", 
    country: "India",
    pricingRange: "$120 - $410",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Kolkata's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Hyderabad", 
    slug: "hyderabad", 
    type: "city", 
    country: "India",
    pricingRange: "$120 - $410",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Hyderabad's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Chennai", 
    slug: "chennai", 
    type: "city", 
    country: "India",
    pricingRange: "$120 - $410",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Chennai's color analysis services reflect the city's coastal lifestyle and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Jakarta", 
    slug: "jakarta", 
    type: "city", 
    country: "Indonesia",
    pricingRange: "$110 - $390",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Jakarta's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Surabaya", 
    slug: "surabaya", 
    type: "city", 
    country: "Indonesia",
    pricingRange: "$110 - $390",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Surabaya's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Bandung", 
    slug: "bandung", 
    type: "city", 
    country: "Indonesia",
    pricingRange: "$110 - $390",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Bandung's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Jakarta", 
    slug: "jakarta", 
    type: "city", 
    country: "Indonesia",
    pricingRange: "$110 - $390",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Jakarta's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Surabaya", 
    slug: "surabaya", 
    type: "city", 
    country: "Indonesia",
    pricingRange: "$110 - $390",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Surabaya's color analysis services reflect the city's rich history and vibrant culture. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
  { 
    name: "Bandung", 
    slug: "bandung", 
    type: "city", 
    country: "Indonesia",
    pricingRange: "$110 - $390",
    businesses: [
      "Color Me Beautiful"
    ],
    description: "Bandung's color analysis services are influenced by the city's diverse culture and dynamic fashion scene. Analysts often focus on practical and versatile palettes suitable for the varying climate."
  },
];

export default locations;