import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { motion } from "framer-motion";

// Import necessary sub-components from ColorAnalysisTest.js
import ColorAnalysisTest, { ColorSection, JewelrySection, Separator, ImageWithDownload } from './ColorAnalysisTest';

const SharedColorAnalysis = () => {
  const [scrollY, setScrollY] = useState(0);
  const [playbook, setPlaybook] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { publicId } = useParams();
  const [isMobile, setIsMobile] = useState(false);

  console.log(publicId);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchPlaybook = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/create-playbook`,
          { 
            params: { public_id: publicId },
            headers: {
              'Content-Type': 'application/json',
            }
          }
        );

        if (response.data.playbook) {
          setPlaybook(response.data.playbook);
        } else {
          setError('Playbook not found');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('An error occurred. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    if (publicId) {
      fetchPlaybook();
    }
  }, [publicId]);

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.5
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
        <ClipLoader color="#ffffff" loading={loading} size={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <h1 className="text-3xl font-bold">{error}</h1>
      </div>
    );
  }

  if (!playbook || !playbook.style_analysis) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white">
        <h1 className="text-3xl font-bold">Error: Playbook data is missing or invalid.</h1>
      </div>
    );
  }

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="bg-gray-900 text-white min-h-screen"
    >
      <div>
        <header
          className="w-full p-6 bg-gray-900 bg-opacity-90 backdrop-filter backdrop-blur-lg"
          style={{
            boxShadow: `0 4px 6px -1px rgba(0, 0, 0, ${Math.min(
              scrollY / 1000,
              0.1
            )})`,
          }}
        >
          <div className="max-w-7xl mx-auto flex justify-center items-center">
            <Link to="/" className="flex items-center">
              <img
                src="/logo.png"
                alt="Palette Hunt Logo"
                className="h-16 w-auto"
              />
            </Link>
          </div>
        </header>
        <main className="p-8">
          <div className="max-w-7xl mx-auto">
            <motion.h1
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-6xl font-bold mb-8 text-center bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text"
            >
              Color Analysis Results
            </motion.h1>

            {playbook.style_analysis.introduction && (
              <>
                <motion.div
                  initial={{ opacity: 0, y: -30 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.4 }}
                  className="text-xl text-gray-300 text-center mb-18 max-w-3xl mx-auto"
                >
                  {playbook.style_analysis.introduction.split('. ').map((sentence, index, array) => (
                    <p key={index} className="mb-4">
                      {sentence.trim() + (index < array.length - 1 ? '.' : '')}
                    </p>
                  ))}
                </motion.div>
                <Separator />
              </>
            )}

            <div className="mt-24">
              {Array.isArray(playbook.style_analysis.color_analysis) && playbook.style_analysis.color_analysis.map((section, index) => (
                <React.Fragment key={index}>
                  {index === 0 && <div className="mb-16" />}
                  <ColorSection
                    title={section.shades}
                    description={section.explanation}
                    colors={section.colors_and_codes}
                    pics={section.pics}
                    isReversed={index % 2 !== 0}
                    isMobile={isMobile}
                  />
                  {index < playbook.style_analysis.color_analysis.length - 1 && (
                    <>
                      <div className="mt-4" />
                      <Separator />
                      <div className="mb-24" />
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>

            {playbook.style_analysis.silver_or_gold && (
              <>
                <div className="mt-8" />
                <Separator />
                <div className="mb-16" />
                <motion.h2
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8 }}
                  className="text-5xl font-bold mb-6 text-center"
                  style={{
                    background: 'linear-gradient(to right, #C0C0C0, #FFD700)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    backgroundClip: 'text',
                    textFillColor: 'transparent'
                  }}
                >
                  Silver or Gold?
                </motion.h2>
                <JewelrySection
                  jewelColor={playbook.style_analysis.silver_or_gold.jewel_color}
                  explanation={playbook.style_analysis.silver_or_gold.explanation}
                  pics={playbook.style_analysis.silver_or_gold.pics}
                  isMobile={isMobile}
                />
              </>
            )}
          </div>
        </main>
      </div>
    </motion.div>
  );
};

export default SharedColorAnalysis;