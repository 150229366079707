import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const ErrorPage = () => {
  return (
    <div className="bg-gray-900 min-h-screen flex items-center justify-center text-white">
      <Helmet>
        <title>Page Not Found - Palette Hunt</title>
        <meta name="description" content="The page you're looking for doesn't exist. Return to Palette Hunt's homepage to discover your perfect color palette." />
      </Helmet>
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Oops! Page Not Found</h1>
        <p className="text-xl mb-8">The page you're looking for doesn't exist or has been moved.</p>
        <Link 
          to="/" 
          className="bg-primary text-white font-semibold py-2 px-6 rounded-full hover:bg-opacity-90 transition duration-300"
        >
          Return to Homepage
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;