import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCog } from 'react-icons/fa';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Capacitor } from '@capacitor/core';

const translations = {
  en: {
    title: "Palette Hunt - AI Color Analysis & Virtual Try-On",
    description: "Discover your perfect color palette with Palette Hunt's AI-powered analysis. Transform your style, try on virtual looks, and get personalized recommendations. Unlock your true fashion potential today!",
    keywords: "color analysis, palette, color palette, palette hunt, palettehunt, AI styling, personal color palette, fashion technology, style recommendations",
    ogTitle: "Palette Hunt - AI-Powered Color Analysis",
    ogDescription: "Discover your perfect color palette with AI-powered analysis.",
    getStarted: "Get started",
    contact: "Contact",
    signIn: "Get Color Analysis",
    discover: "Discover",
    yourPerfect: "Your Perfect",
    colorPalette: "Color Palette",
    findYourShades: "Find your most flattering shades with our AI-powered color analysis. Upload your photos and see yourself transformed in your perfect colors.",
    imagesCreated: "+15,000 images created",
  }
};

const LandingPage = () => {
  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const getStartedRef = useRef(null);
  const navigate = useNavigate();
  const isNativeMobile = Capacitor.isNativePlatform();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth >= 768 && window.innerWidth < 1024);
  const [language, setLanguage] = useState('en');
  const t = translations[language];
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const languageDropdownRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    setIsLanguageDropdownOpen(false);
    if (lang === 'es') {
      navigate('/es');
    } else if (lang === 'pt') {
      navigate('/pt');
    } else {
      navigate('/');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (languageDropdownRef.current && !languageDropdownRef.current.contains(event.target)) {
        setIsLanguageDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const ImageWithFallback = ({ src, alt, className, objectPosition }) => {
    const [error, setError] = useState(false);

    const handleError = (e) => {
      console.error(`Failed to load image: ${src}`);
      setError(true);
    };

    return (
      <div className={`relative ${className} ${!isMobile ? 'transition-transform duration-300 ease-in-out transform hover:scale-110' : ''}`}>
        {!error ? (
          <img
            src={src}
            alt={alt}
            className="w-full h-full object-cover rounded-lg"
            style={{ objectPosition: objectPosition || 'center' }}
            onError={handleError}
          />
        ) : (
          <div className="absolute inset-0 flex items-center justify-center bg-red-500 text-white text-xs p-2 text-center rounded-lg">
            Image failed to load: {src}
          </div>
        )}
      </div>
    );
  };

  const scrollToGetStarted = () => {
    getStartedRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  useEffect(() => {
    localStorage.setItem('hasSeenOnboarding', 'true');
  }, []);

  const handleSettingsClick = async () => {
    console.log('Settings button clicked');
    if (Capacitor.isNativePlatform()) {
      console.log('Native mobile, navigating to settings');
      navigate('/settings');
    } else {
      console.log('Web, redirecting to settings');
      navigate('/settings');
    }
  };

  return (
      <div className="bg-pink-50 text-gray-800 flex flex-col min-h-screen">
        <header className="p-4 flex justify-between items-center mt-10 -mb-8">
          <img src="/logo.png" alt="Palette Hunt Logo" className="h-10 ml-4" />
          <button onClick={handleSettingsClick} className="text-gray-800 mr-4">
            <FaCog size={24} />
          </button>
        </header>
        <main className="flex-grow">
          <section className="hero max-w-7xl mx-auto px-4 pt-2">
            <div className="flex flex-col items-start mt-5">
              <div className="w-full text-left">
                <h1 className="text-2xl font-bold text-black text-center">
                  Discover your <span className="inline-block relative">
                    perfect palette
                    <span className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-red-500 via-yellow-500 to-blue-500 rounded-full"></span>
                  </span>
                </h1>
              </div>
              <div className="md:w-1/2 flex flex-col items-center justify-center px-4 md:px-0 mt-4">
                <div ref={containerRef} className="relative w-full mb-16 md:mb-0">
                  <svg ref={svgRef} className="absolute top-0 left-0 w-full h-full text-gray-300 pointer-events-none z-10" />
                  {/* Step 1: Upload your photos */}
                  <div className="mb-6 relative">
                    <div className="flex items-start mb-2">
                      <div className="bg-gray-800 text-white rounded-full w-8 h-8 flex items-center justify-center mr-3">
                        <span className="text-lg font-bold">1</span>
                      </div>
                      <h3 className="text-xl font-semibold text-gray-800 flex-grow">Upload your selfies</h3>
                    </div>
                    <div className="flex justify-center space-x-4">
                      <ImageWithFallback 
                        src="/laurita.jpg" 
                        alt="Upload photo 1" 
                        className="w-16 h-16 object-cover rounded-lg shadow-md" 
                        objectPosition="center 30%" 
                      />
                      <ImageWithFallback 
                        src="/laurita4.jpg" 
                        alt="Upload photo 2" 
                        className="w-16 h-16 object-cover rounded-lg shadow-md" 
                      />
                      <ImageWithFallback 
                        src="/photo_grid_3.jpg" 
                        alt="Upload photo 2" 
                        className="w-16 h-16 object-cover rounded-lg shadow-md" 
                      />
                      <ImageWithFallback 
                        src="/laurita-6.jpg" 
                        alt="Upload photo 3" 
                        className="w-16 h-16 object-cover rounded-lg shadow-md" 
                        objectPosition="center 30%" 
                      />
                    </div>
                  </div>
                  
                  {/* Step 2: Get your AI model */}
                  <div className="mb-4 relative">
                    <div className="flex items-start mb-2">
                      <div className="bg-gray-800 text-white rounded-full w-8 h-8 flex-shrink-0 flex items-center justify-center mr-3 mt-2">
                        <span className="text-lg font-bold leading-none">2</span>
                      </div>
                      <h3 className="text-xl font-semibold text-gray-800 flex-grow">Get transformed in your perfect colors with AI photos</h3>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="col-span-2 mb-1 relative">
                        {/* Removed the Summer palette heading from here */}
                      </div>
                      <div className="flex justify-center pr-1">
                        <ImageWithFallback 
                          src="/colorPalette.jpeg" 
                          alt="AI Model 1" 
                          className="w-28 h-36 object-cover rounded-lg shadow-lg" 
                        />
                      </div>
                      <div className="flex justify-start items-center -ml-4">
                        <ImageWithFallback 
                          src="/sunny-yellow.webp" 
                          alt="AI Model 2" 
                          className="w-28 h-36 object-cover rounded-lg shadow-lg mr-2" 
                        />
                        <div className="flex flex-col">
                          <div className="w-16 h-16 rounded-lg bg-[#E2725B] mb-2 flex items-center justify-center">
                            <span className="text-xs text-white">#E2725B</span>
                          </div>
                          <div className="w-16 h-16 rounded-lg bg-[#FFC300] flex items-center justify-center">
                            <span className="text-xs text-white">#FFC300</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Get Color Analyzed Now button */}
                </div>
              </div>
            </div>
          </section>
          
          {/* Scrollable boxes section */}
          <div className="relative pb-1 pt-0 -mt-12">
            <h3 className={`text-left font-bold ml-6 mb-2 ${window.innerWidth >= 428 ? 'text-2xl' : 'text-xl'} sticky top-0 z-10`}>What you'll get:</h3>
            <div className="overflow-x-auto whitespace-nowrap">
              <div className="inline-flex space-x-4 ml-6">
                <div className="w-40 h-28 bg-red-200 rounded-lg p-2 inline-block border-2 border-red-800 overflow-hidden">
                  <h4 className="text-sm font-semibold mb-1 truncate">🎨 Color Analysis</h4>
                  <p className="text-sm leading-tight">Get explained your<br />ideal seasonal<br />palettes</p>
                </div>
                <div className="w-40 h-28 bg-blue-200 rounded-lg p-2 inline-block border-2 border-blue-800 overflow-hidden">
                  <h4 className="text-sm font-semibold mb-1 truncate">👗 Virtual Try On</h4>
                  <p className="text-sm leading-tight">See how colors<br />look on you<br />with AI photos</p>
                </div>
                <div className="w-40 h-28 bg-green-200 rounded-lg p-2 inline-block border-2 border-green-800 overflow-hidden mr-4">
                  <h4 className="text-sm font-semibold mb-1 truncate">🎭 Playground</h4>
                  <p className="text-sm leading-tight">Try new styles<br />styles with your<br />AI model</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center bg-burgundy p-6 rounded-lg mb-4">
            {window.innerWidth >= 428 ? (
              <a href="/color-analysis" className="bg-gray-800 text-pink-50 font-bold py-5 px-12 rounded-full transition duration-300 ease-in-out transform hover:scale-105 w-80 text-center inline-block whitespace-nowrap text-xl mt-8 mb-8">
                Get Color Analyzed Now
              </a>
            ) : (
              <a href="/color-analysis" className="bg-gray-800 text-pink-50 font-bold py-4 px-10 rounded-full transition duration-300 ease-in-out transform hover:scale-105 w-64 flex items-center justify-center text-center -mt-1 whitespace-nowrap">Get Color Analyzed Now</a>
            )}
          </div>

          {/* Add this div to create a clean break between sections */}
          <div className="w-full h-12 bg-gray-900"></div>
          {/* Testimonial Section with Rating and Image Count */}

          {/* How it works section */}
          <section className="py-16 bg-gray-900">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl sm:text-4xl font-bold mb-8 text-center text-pink-50">
                Finally understand what colors suit you
              </h2>
              <p className="text-pink-50 text-left text-xl mb-12 max-w-2xl mx-auto">
                Our AI-powered color analysis provides detailed explanations and shows you transformed in your perfect colors using advanced AI-generated photos. <br/><br/>Discover your ideal palette and see yourself in a whole new light.
              </p>
              <div className="flex flex-col md:flex-row items-center justify-between">
                <div className="w-full md:w-1/2 space-y-8">
                  <img
                    src="/mobile3.png"
                    alt="Palette Hunt color analysis on mobile devices"
                    className="w-full"
                  />
                </div>
                
                {/* <div className="w-full md:w-1/2 space-y-8 ml-2">
                  <img
                    src="/result.jpeg"
                    alt="Palette Hunt colors fit"
                    className="w-full rounded-lg shadow-lg"
                  />
                  <img
                    src="/result2.jpeg"
                    alt="Palette Hunt colors palette"
                    className="w-full rounded-lg shadow-lg"
                  />
                </div> */}
              </div>
            </div>
          </section>

          {/* Playground Demo Section */}
          <section className="playground-demo py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className={`flex flex-col ${isMobile || isTablet ? '' : 'lg:flex-row'} items-center justify-between ${isMobile || isTablet ? '' : 'lg:space-x-16'}`}>
                <div className={`${isMobile || isTablet ? 'w-full text-center' : 'lg:w-1/2 mb-8 lg:mb-0'}`}>
                  <h2 className="text-3xl sm:text-4xl font-bold mb-6 text-gray-800">Your Style, Reimagined</h2>
                  <p className="text-xl text-gray-800 mb-6 text-left">
                    Step into our virtual try-on playground where you can experiment every style you can imagine. <br/><br/>Witness yourself in stunning AI photos of you in different colors.
                  </p>
                </div>
                <div className={`w-full`}>
                <img 
                      src="/playground_mobile.jpeg" 
                      alt="Palette Hunt Analysis Demo" 
                      style={{ width: '100%', height: 'auto', objectFit: 'cover' }} 
                      className="w-full"
                    />
                </div>
              </div>
              <div className="flex justify-center bg-burgundy p-6 rounded-lg mt-8 mb-12">
                <a href="/color-analysis" className="bg-gray-800 text-pink-50 font-bold py-4 px-10 rounded-full transition duration-300 ease-in-out transform hover:scale-105 w-64 flex items-center justify-center text-center whitespace-nowrap">Get Color Analyzed Now</a>
              </div>
            </div>
          </section>

          {/* Color Analysis Demo Section
          <section className="color-analysis-demo py-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className={`flex flex-col ${isMobile || isTablet  ? '' : 'lg:flex-row'} items-center justify-between ${isMobile ? '' : 'lg:space-x-16'}`}>
                <div className={`${isMobile || isTablet  ? 'w-full text-center' : 'lg:w-1/2 mb-8 lg:mb-0 order-2'}`}>
                  <h2 className="text-3xl font-bold mb-6 text-gray-800">Crack Your Color Code</h2>
                  <p className="text-xl text-gray-800 mb-4">
                    A color analysis shouldn't only be for the rich. Find out what colors look good on you and how to use them to your advantage.
                  </p>
                  <p className="text-xl text-gray-800 mb-6">
                    We don't just analyze your most flattering colors, we also show you how they look on you.
                  </p>
                </div>
                <div className={`${isMobile || isTablet  ? 'w-full' : 'lg:w-1/2 order-1'}`}>
                  <div className={`rounded-lg overflow-hidden shadow-xl ${isMobile || isTablet ? 'mt-6' : 'skew-left transition-all duration-300 hover:skew-none'}`} style={{ marginRight: isMobile ? '0' : '15%' }}>
                    <img 
                      src="/playbook-thumbnail.jpeg" 
                      alt="Palette Hunt Analysis Demo" 
                      style={{ width: '100%', height: 'auto', objectFit: 'cover' }} 
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center bg-burgundy p-6 rounded-lg mt-8 mb-12">
                    <a href="/color-analysis" className="bg-gray-800 text-pink-50 font-bold py-3 px-8 rounded-full transition duration-300 ease-in-out transform hover:scale-105 w-64 text-center inline-block whitespace-nowrap">Get Color Analyzed Now</a>
                  </div>
            </div>
          </section> */}
      </main>
    </div>
);
};

export default LandingPage;